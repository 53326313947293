.social-media {
  @extend %fa-icon;
  @extend .fab;
  font-family: "Font Awesome 6 Brands" !important;

  &::before {
    display: inline-block;
    visibility: visible;
    width: 30px;
    font-size: $font-size-icon-l;
    text-align: center;
  }

  &-facebook::before {
    content: fa-content($fa-var-facebook-f);
  }

  &-instagram::before {
    content: fa-content($fa-var-instagram);
  }

  &-pinterest::before {
    content: fa-content($fa-var-pinterest);
  } 

  &-twitter::before {
    content: fa-content($fa-var-twitter);
  }

  &-youtube::before {
    content: fa-content($fa-var-youtube);
  }

  &-yelp::before {
    content: fa-content($fa-var-yelp);
  }

  &-email::before {
    @extend .fas;
    content: fa-content($fa-var-envelope);
    font-family: "Font Awesome 6 Free" !important;
  }
}

.social-media-item {
  a {
    @extend .social-media;
    
    overflow: hidden;
    width: 30px;
    height: 30px;
    .ms-footer__link__text {
      visibility: hidden;
    }
    &:focus{
      @include default-outline;
    }
  }

  &-facebook a {
    @extend .social-media-facebook;
  }

  &-instagram a {
    @extend .social-media-instagram;
  }

  &-twitter a {
    @extend .social-media-twitter;
  }

  &-pinterest a {
    @extend .social-media-pinterest;
  }

  &-youtube a {
    @extend .social-media-youtube;
  }

  &-yelp a {
    @extend .social-media-yelp;
  }

  &-email a {
    @extend .social-media-email;
  }
}
