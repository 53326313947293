.tasting-tours {
  .errorBox {
    &[hidden] {
      display: block !important;
      @extend %error-alert;
      max-height: initial;
    }

    &:empty {
      display: none !important;
    }
  }

  &-hidden {
    display: none;
  }

  &-button {
    @extend %btn-marketing;
    margin-top: 10px;
  }

  &-consent-legal {
    font-size: $font-size-body-xs;
  }
}
