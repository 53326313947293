.landing {
    background-image: none;
    background-color: $color-background-landing-body;
    color: $color-text-landing;
    font-family: $font-family-body-landing;
    font-size: $font-size-body-landing;
    line-height: 1.5;

    #renderPage header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: static;
        padding: 2rem 0;
        margin: 0;
        background-image: none;
        background-color: transparent;
    }

    #main {
        width: auto;
        max-width: $max-landing-hero-width;
        padding: 0;
        margin: 0 auto;
        border: 0;
        outline: 0;
        background-image: none;
        background-color: transparent;
    }

    #renderPage footer {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        background-image: none;
        background-color: transparent;
    }

    %styles-landing {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            font-family: $font-family-heading-landing;
        }

        h1,
        .h1 {
            color: $color-heading-landing;
            font-size: $font-size-heading-landing / 2;

            @include media-breakpoint-up(sm) {
                font-size: $font-size-heading-landing;
            }
        }

        h2,
        .h2 {
            color: $color-text-landing;
            font-size: $font-size-heading-landing / 2;

            @include media-breakpoint-up(sm) {
                font-size: $font-size-heading-landing;
            }
        }

        h5,
        .h5 {
            color: $color-appellation-landing;
            font-size: $font-size-appellation-landing;
            font-style: italic;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }

        .btn {
            padding: 0.8rem 1.2rem;
            border-color: $color-button-landing-border;
            background-color: $color-button-landing-background;
            color: $color-button-landing-text;
            font-family: $font-family-button-landing;
            font-size: $font-size-button-landing;
            font-weight: bold;
            text-transform: uppercase;

            &:hover,
            &:focus,
            &:active {
                border-color: $color-button-landing-border-hover;
                background-color: $color-button-landing-background-hover;
                color: $color-button-landing-text-hover;
            }

            .fa-chevron-right {
                display: none;
            }
        }

        .hero-picture img,
        .ms-content-block__image > picture img {
            width: 100%;
            height: auto;
            min-height: 250px;
            object-fit: cover;
        }

        .content-with-media-media-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #main > *,
    &-footer {
        @extend %styles-landing;
        & > .row {
            margin: 0;
            padding: 0;
        }
    }

    &-hero {
        position: relative;
        height: auto;
        min-height: 0;
        margin: 0;

        .hero-text-alignment-container,
        .ms-content-block__details {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 1rem;

            @include media-breakpoint-up(sm) {
                padding: 3rem 4rem;
            }

            .hero-heading,
            p {
                max-width: 430px;
                color: $color-white;
                text-shadow: 0 0 5px $color-black;
            }

            p {
                font-size: $font-size-body-landing * 1.1;
            }

            .hero-cta button,
            .ms-content-block__cta button,
            .hero-cta a,
            .ms-content-block__cta a {
                @extend %btn-default;
                @extend .btn-primary;
                font-size: $font-size-button-landing * 1.2;
                box-shadow: 0 0 5px transparentize($color-black, 0.7);
            }

            &.x-left {
                align-items: flex-start;
            }

            &.x-right {
                align-items: flex-end;
                text-align: right;
            }

            &.x-center {
                align-items: center;
                text-align: center;
            }

            &.y-top {
                justify-content: flex-start;
            }

            &.y-bottom {
                justify-content: flex-end;
            }

            &.y-center {
                justify-content: center;
            }
        }
    }

    &-overview,
    &-featured,
    &-wine-finder,
    &-content-grid {
        width: 100%;
        max-width: $max-landing-page-width;
        margin: 1rem 0;

        @include media-breakpoint-up(sm) {
            width: $page-width;
            margin: 3% auto;
        }
    }

    &-overview {
        padding: 0 1rem;
        margin: 2rem 0;
        text-align: center;

        @include media-breakpoint-up(sm) {
            padding: 2rem 3rem;
            margin: 0 auto;
        }
    }

    &-featured,
    &-wine-finder,
    &-promo {
        background-color: $color-background-landing-content;
    }

    &-featured > div,
    &-wine-finder > div,
    &-content-grid,
    &-content-grid > .row > div {
        padding: 0;
    }

    &-featured {
        > div {
            flex: auto;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }

        > div:last-child:not(:only-child) {
            display: flex;
            align-items: center;
        }

        .content-with-media-media,
        .content-with-media-media-image {
            height: 100%;
        }

        .content-with-media-text {
            padding: 1rem 1.5rem;

            @include media-breakpoint-up(sm) {
                padding: 2rem 3rem;
            }
        }
    }

    &-wine-finder .hero-text-alignment-container,
    .ms-content-block__details {
        padding: 1rem 1.5rem;
        @include media-breakpoint-up(sm) {
            padding: 2rem 3rem;
        }
    }

    &-content-grid > .row {
        margin: 0;

        > div {
            flex: auto;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 48.5%;
            }
        }

        > div:empty {
            display: none;
        }

        > div:not(:first-child) {
            margin-top: 1rem;

            @include media-breakpoint-up(lg) {
                margin-top: 3%;
            }
        }

        > div:nth-child(2) {
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }

        > div:nth-child(2n-1) {
            @include media-breakpoint-up(lg) {
                margin-right: 1.5%;
            }
        }

        > div:nth-child(2n) {
            @include media-breakpoint-up(lg) {
                margin-left: 1.5%;
            }
        }
    }

    &-promo {
        height: 100%;
        min-height: 0;
        margin: 0;

        .hero-text-alignment-container,
        .ms-content-block__details {
            position: static;
            padding: 1rem 1.5rem;

            @include media-breakpoint-up(sm) {
                padding: 2rem 3rem;
            }
            .hero-cta button,
            .ms-content-block__cta button,
            .hero-cta a,
            .ms-content-block__cta a {
                @extend %btn-default;
                @extend .btn-primary;
                font-weight: bold;
                font-family: $font-family-body-landing;
                font-size: $font-size-link-xxs;
                padding: 0.8rem 1.2rem;
            }
        }
    }
    &-footer {
        display: block;
        max-width: $page-width;
        padding: 2rem 0 0;
        border: 0;
        color: $color-text-landing-footer;
        font-family: $font-family-body-landing;
        font-size: $font-size-body-landing;
        line-height: 1.5;
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
        .col-12:first-child {
            align-items: center;
            justify-content: center;
            @include breakpoint(lg) {
                align-items: initial;
                justify-content: initial;
            }
            .ms-footer__list {
                &-item:not(:first-child) {
                    @include breakpoint(lg) {
                        margin: 0 0 0 1rem;
                    }
                }
            }
        }
        .col-12:last-child {
            .ms-footer__list {
                justify-content: center;
                margin: 0 auto;
                &-item:first-child {
                    width: 100%;
                }
                &-item:last-child {
                    @include breakpoint(md) {
                        margin-top: 0.5rem;
                    }
                    @include breakpoint(lg) {
                        margin: 0 0 0 1rem;
                    }
                }
                @include breakpoint(lg) {
                    justify-content: left;
                    margin: 0;
                    text-align: left;
                }
            }
        }
        &-links {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: column;
            margin-top: 0.5rem;
            @include breakpoint(md) {
                &:first-child {
                    margin-top: 0;
                }
            }
            @include breakpoint(lg) {
                flex-direction: row;
                width: auto;
                margin-top: 0;
            }
            .footer-category-slot {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }

            .footer-item-link a,
            .ms-footer__link {
                color: $color-link-landing;
                font-family: $font-family-body-landing;
                font-size: $font-size-link-landing;
                font-weight: bold;
                text-transform: uppercase;

                &:hover,
                &:focus,
                &:active {
                    color: $color-link-landing-hover;
                }
            }
        }

        > div {
            padding: 0;

            .footer-category-slot {
                padding: 0;

                &:not(:first-child) {
                    margin-top: 0.5rem;

                    @include media-breakpoint-up(lg) {
                        margin: 0 0 0 1rem;
                    }
                }
            }
        }
    }

    &-copyright {
        padding: 1rem 0 !important;

        @include media-breakpoint-up(lg) {
            padding: 0 !important;
            margin-bottom: 1rem;
        }
    }
    // &-footer {
    //     display: block;
    //     max-width: $page-width;
    //     padding: 2rem 0 0;
    //     border: 0;
    //     color: $color-text-landing-footer;
    //     font-family: $font-family-body-landing;
    //     font-size: $font-size-body-landing;
    //     line-height: 1.5;
    //     text-align: center;

    //     @include media-breakpoint-up(lg) {
    //         text-align: left;
    //     }

    //     &-links {
    //         display: flex;
    //         flex-wrap: wrap;

    //         .footer-category-slot {
    //             width: 100%;

    //             @include media-breakpoint-up(lg) {
    //                 width: auto;
    //             }
    //         }

    //         .footer-item-link a {
    //             color: $color-link-landing;
    //             font-family: $font-family-link-landing;
    //             font-size: $font-size-link-landing;
    //             font-weight: bold;
    //             text-transform: uppercase;

    //             &:hover,
    //             &:focus,
    //             &:active {
    //                 color: $color-link-landing-hover;
    //             }
    //         }
    //     }

    //     > div {
    //         padding: 0;

    //         .footer-category-slot {
    //             padding: 0;

    //             &:not(:first-child) {
    //                 margin-top: 0.5rem;

    //                 @include media-breakpoint-up(lg) {
    //                     margin: 0 0 0 1rem;
    //                 }
    //             }
    //         }
    //     }
    // }

    // &-copyright {
    //     padding: 1rem 0 !important;

    //     @include media-breakpoint-up(lg) {
    //         padding: 0 !important;
    //         margin-bottom: 1rem;
    //     }
    // }
    .clickable-container {
        .ms-content-block.landing-logo {
            .ms-content-block__details {
                display: none;
            }
        }
    }
}


.ms-content-block {
    .ms-content-block__image:empty,
    .ms-content-block__image img[height="1px"],
    .ms-content-block__title:empty,
    .ms-content-block__text:empty,
    .ms-content-block__cta:empty {
      display: none;
    }
  }