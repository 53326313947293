.promo-banner-cookie {
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px 10px 3px;
        background-color: $color-background-shop;

        @include media-breakpoint-up(md) {
            padding: 8px 10px 5px;
        }
    }

    &-content {
        flex: 1;
        margin-right: 3px;
        font-family: $font-family-link;
        font-size: 13px;
        text-align: center;
        color: $color-white;

        > *:empty {
            display: none;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            color: inherit;
            margin-bottom: 0;
        }

        a {
            &,
            &:active,
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    &-close {
        @include add-icon($msv-x-shape);
        padding: 0;
        border: 0;
        outline: 0;
        background-color: transparent;
        color: $color-white;

        &:focus {
            outline: 0;
        }

        &::before {
            font-size: 20px;
        }
    }
}
