#renderPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  header {
      z-index: 10;
      flex: 0 1 auto;
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
  }

  main {
      flex: 0 1 auto;
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      padding: 0;
      background-color: transparent;

      @include media-breakpoint-up(lg) {
          //width: 90%;
          margin: -90px auto 0;
      }
  }

  footer {
      z-index: 5;
      flex: 1 0 auto;
      margin-top: auto;
  }
}

.shop #renderPage {
  main {
      flex: 1 0 auto;
  }

  footer {
      flex: 0 1 auto;
  }
}