$msv-sign-in-font-size: 13px;
$msv-sign-in-button-margin-top: 1rem;
$msv-sign-in-facebook-color: #3b5998;
$msv-sign-in-account-item-margin-top: 1rem;

$width-page--aad: 100%;
$min-width-page--aad: 0;
$max-width-page--aad: 600px;
$margin-page--aad: 10rem auto 4rem;
$margin-page-mobile--aad: 2rem auto;
$padding-page--aad: 2rem auto 0;
$padding-page-mobile--aad: 1rem auto 0;
$color-background-container--aad: $color-white;
$error-alert-color: #721c24;
$error-alert-background-color: #f8d7da;
$error-alert-border-color: #f5c6cb;

%form-control-el {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.aad {
  max-width: $max-width-page--aad;
  margin: $margin-page-mobile--aad;
  font-size: $msv-sign-in-font-size;

  @include media-breakpoint-up(lg) {
    margin: $margin-page--aad;
  }
  > .row {
    margin: 0;
  }
  .row > div {
    padding: 0;
    margin: 0;
  }
  .ms-header__account-info {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    @include media-breakpoint-up(lg) {
      &::before {
        margin-right: 0.5rem;
        padding: 0.375rem;
        content: "Open Daily 10:00am-5:00pm";
        display: block;
        font-size: 18px;
        color: $color-black;
      }
    }
  }
  &-message {
    padding: 1.5rem 2rem;
    background-color: $color-merlin;

    .ms-content-block__title {
      display: none;
    }

    h5,
    .h5 {
      color: $color-white;
    }

    p {
      margin-bottom: 0;
      color: $color-silver;
      line-height: 1.5;
    }

    a {
      color: $color-bisonhide;

      &:hover,
      &:focus,
      &:active {
        color: $color-kumera;
      }
    }
  }
}
.ms-sign-in {
  max-width: $max-width-page--aad;
  font-size: $font-size-body-m;
  padding: 1.5rem 2rem;
  background-color: $color-background-container--aad;
  color: $color-text-primary;
  max-width: none;
  margin: 0;
  @include media-breakpoint-up(sm) {
    padding: 3rem 4rem;
  }

  &__container {
    display: block;
    width: 100%;
    margin: 0;
    label {
      margin-bottom: 0;
      color: $color-mineshaft;
      font-size: $font-size-body-m;
    }
  }
  &__item-error-text,
  &__page-error-text {
    margin: 0;
  }
  &__social-account-picture {
    display: inline-block;
    vertical-align: middle;
  }
  &__sign-in-heading,
  &__sign-up-heading {
    @include account-heading();
    font-family: $font-family-heading-shop;
    font-weight: normal;
  }
  &__sign-in-section {
    flex: 1;
    h2,
    .h2 {
      margin-bottom: 2rem;
    }
  }
  &__sign-up-section {
    flex: 1;
    h2,
    .h2 {
      display: none;
    }
  }
  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;
    &-input {
      @extend %form-control-el;
      @extend %input-shop;
    }
  }
  &__container-forget-password {
    text-align: center;
    margin: 0.75rem 0;
  }
  &__forget-password {
    @extend %link-shop;
    display: inline-block;
  }
  &__sign-in-button {
    @extend %btn-default;
    @extend .btn-primary;
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
  }
  &__sign-up-link {
    @extend %btn-default;
    @extend .btn-secondary;
    width: 100%;
  }
  &__social-accounts {
    display: none;
  }
  &__sign-up-description {
    position: relative;
    padding: 6.5px 0;
    margin: 1rem 0;
    color: transparent;
    display: none;
  }

  &__page-error,
  &__item-error {
    @include add-icon($msv-IncidentTriangle);
    color: $error-alert-color;
    background-color: $error-alert-background-color;
    border: 1px solid $error-alert-border-color;
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    p {
      display: inline-block;
      margin-bottom: 0;
      line-height: 1.2;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
    &::before {
      margin-right: 0.5rem;
    }
  }
  &__verifying-modal-message {
    text-align: center;
    color: $color-white;
    font-size: $font-size-body-xl;
  }
}
.sign-in-show-password {
  float: right;
  margin: -27px 5px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
}

.icon-show-password {
  @extend .fas;
  @extend .fa-eye;
}

.icon-hide-password {
  @extend .fas;
  @extend .fa-eye-slash;
}
