$nav-container-width: 350px;
$nav-container-height: 100%;
$nav-container-height-screen: 100vh;
$nav-container-padding-top: 200px;
$nav-sub-container-padding-top-desktop: 26vh;
$nav-button-padding-desktop: 1.5rem 2rem 0.5rem;
$nav-button-padding-mobile: 1rem;

%nav-toggle {
  @include add-icon($msv-GlobalNavButton);
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;
  z-index: 80;
  padding: 0.9rem 1rem 0.5rem;
  border-radius: 0;
  border: none;
  color: $color-link-primary;
  padding: 1rem 2rem 0.5rem;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
}

%nav-toggle-icon-properties {
  &:before {
    color: $color-link-primary;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background: no-repeat center center;
    background-size: 100% 100%;
  }
}

//Navigation Toggle Button
.ms-header {
  &__nav-icon {
    @extend %nav-toggle;
    @extend %nav-toggle-icon-properties;
    position: relative;
    float: right;
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

//Navigation styles
.ms-nav {
  max-height: 0;
  text-align: right;
  overflow-y: hidden;
  @include media-breakpoint-up(lg) {
    max-width: 960px;
    margin: 0.95rem auto 0;
    text-align: right;
    overflow-y: visible;
    max-height: unset;
  }
  //parent menu item container
  &__list[role="menu"] {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    @include media-breakpoint-up(md) {
      display: inline-flex;
    }
    .ms-nav__list__item {
      list-style: none;

      position: relative;
      &__button,
      &__link {
        @extend %btn-default-partial;
        @extend %link-nav-primary;
        padding: 0.5rem 0;
        text-align: right;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 0.65rem 0;
        font-size: 13px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: right;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
        text-decoration: none;
        width: 100%;
        &.selected {
          color: $color-link-primary-hover !important;
        }
        &:focus,
        &:hover{
          color: $color-mineshaft !important;
        }
        @include media-breakpoint-up(lg) {
          text-align: initial;
          padding: 0.375rem 0.75rem 0.75rem;
          font-size: 9.5px;
          text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
          text-align: initial;
        }
      }
      &__link.selected {
        font-weight: bold;
      }
      &__button.selected {
        font-weight: bold;
      }
      //Child menu item container
      ul {
        @include fadeIn($duration: 0.6s);
        @extend %subnav-container;
        display: block;
        min-width: 0;
        margin: 0;
        border-radius: 0;
        background-size: cover;
        transition: visibility 0s, opacity 0.5s linear;
        // 992px
        @include breakpoint(lg) {
          background-image: $asset-background-header-dropdown;
          left: 50%;
          transform: translateX(-50%);
          padding: 0.5rem 0;
          background-color: $color-tana;
        }
        li {
          @extend %subnav-item;
          @extend %link-nav-secondary;
          text-align: right;
          color: $color-kumera;
          text-transform: capitalize;
          text-decoration: none;
          @include breakpoint(lg) {
            padding: 0.2rem 1.5rem;
            text-align: left;
          }
          a {
            padding: 0;
            text-transform: capitalize;
            letter-spacing: 0;
            text-shadow: none;
            text-decoration: none;
            color: $color-kumera;
            font-size: 13px;
            display: block;
            width: 100%;
            clear: both;
            font-weight: normal !important;
            text-align: inherit;
            white-space: nowrap;
            border: none;
            background-color: transparent;
            font-family: $font-family-body;
            cursor: pointer;

            &.selected,
            &:hover,
            &:focus,
            &:active {
              color: $color-mineshaft;
            }
          }
        }
      }
    }
  }
  //Navigation Mobile/Tab View
  @media (max-width: $breakpoint-lg) {
    align-items: center;
    z-index: -1;
    position: absolute !important;
    top: 0;
    width: 100%;

    ul {
      background-color: rgba(219, 212, 192, 0.95);
      display: block !important;
      padding: 10.7rem 1rem 1rem 1rem;
      @media (min-width: $breakpoint-xxs) {
        padding: 6.3rem 2rem 1rem;
      }

      //child items mobile/tab

      .ms-nav__list__item {
        &:last-child {
          a {
            padding-top: 0.7rem;
          }
        }
        ul {
          background-color: transparent;
          padding: 0;
          li {
            padding: 0;
            text-align: right;
            .ms-nav__list__item__link {
              &.selected {
                font-weight: normal;
              }
              width: 100%;
              border: none;
              background-color: transparent;
              color: $color-link-footer;
              font-family: $font-family-body;
              font-size: $font-size-body-s;
              cursor: pointer;
              text-decoration: none;
              padding: 0.25rem 0 0.75rem;
              display: block;
              text-transform: capitalize;
              font-weight: normal;
              letter-spacing: 0;
              &:hover,
              &:focus,
              &:active,
              &:not(:disabled):not(.disabled):active,
              &:not(:disabled):not(.disabled):focus,
              &:not(:disabled):not(.disabled):active:focus {
                border: none;
                box-shadow: none;
                background-color: transparent;
                color: $color-link-footer-hover;
              }
              @include media-breakpoint-up(md) {
                font-size: $font-size-body-m;
              }
            }
          }
        }
      }
    }
    &.nav-open,
    &.nav-closed {
      transition: max-height 1s cubic-bezier(1, 0.7, 0.85, 0.99);
    }
    &.nav-closed {
      position: absolute !important;
      top: 0;
      width: 100%;
      max-height: 0;
      display: none;
    }
    &.nav-open {
      height: auto;
      max-height: 1200px;
    }
    //subnav
    .subnav-show {
      display: block;
      max-height: 300px;
      visibility: visible;
    }

    .subnav-hide {
      max-height: 0;
      visibility: hidden;
      display: none;
    }

    .subnav-show {
      transition: max-height 1s ease;
    }

    .subnav-hide {
      transition: max-height 0.3s ease;
    }
  }
}
