.ms-cart {
  @extend %container-default;
  padding: 0 15px;
  .msc-cart {
    &__heading {
      @extend %text-shop-heading-primary;
      @extend %text-shop-heading-container;
    }
    &-line {
      &__product-title {
        font-size: $font-size-body-m;
      }
    }
  }

  .cart-lineitems-header {
    @extend %text-shop-subheading;
    color: $color-black;
  }

  .cart-lineitems-header,
  .cart-lineitems-container .cart-line-item-product {
    display: flex;

    > td {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .header-items,
    .cart-line-item-product-details {
      width: 50%;
      @include media-breakpoint-up(sm) {
        width: 55%;
      }

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    .cart-line-item-product-details {
      padding-right: 1rem;

      .cart-line-item-product-heading {
        font-weight: bold;
      }
    }

    .header-qty,
    .cart-line-item-price-quantity {
      width: 25%;

      @include media-breakpoint-up(md) {
        width: 20%;
      }
    }
    .header-qty {
      width: 30%;
      @include media-breakpoint-up(sm) {
        width: 25%;
      }
    }
    .header-qty,
    .cart-line-item-price-quantity {
      .msc-cart-line__quantity__select-menu {
        @extend %input-shop-qty;
        //remove native browser styling //Safari
        @extend %select-input-override;
        width: 55px;
        max-width: 60px;
        text-align: center;
        padding: 0.35rem 0.25rem 0.25rem 0.55rem;
        border: 1px solid $color-mineshaft;
      }

      .msc-cart-line__remove-item {
        @extend %btn-default;
        color: $color-kumera;
        padding: 0;
        margin: 0.375rem 0.75rem 0.375rem 0.5rem;

        &:hover,
        &:active,
        &:focus {
          color: $color-link-muted-hover;
        }

        &::before {
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-times-circle);
          font-size: $font-size-icon-m;
        }
      }
    }

    .header-unitprice,
    .msc-cart-line__product-price,
    .cart-line-item-product-price {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        width: 20%;
      }
    }
    .msc-cart-line__product-price,
    .cart-line-item-product-price {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: $font-size-body-m;
    }

    .header-total {
      justify-content: flex-end;
      text-align: end;
    }

    .header-total,
    .cart-line-item-price {
      width: 20%;

      @include media-breakpoint-up(md) {
        width: 10%;
      }
    }

    .cart-line-item-price {
      flex-direction: column;
      align-items: flex-end;
      font-size: $font-size-body-m;
      text-align: right;
      justify-content: center;

      .cart-line-item-price-savings-text {
        text-align: right;
      }
    }
  }

  .cart-lineitems-container {
    padding: 0;
    margin: 1rem 0;
    list-style-type: none;

    .msc-cart-lines-item {
      padding: 1rem 0;
      border-bottom: 1px solid $color-tana;
    }
  }

  .cart-checkout-button {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .cart-checkout-button-sign-in,
    .cart-checkout-button-checkout {
      @extend .btn-primary;
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .cart-checkout-button-guest {
      @extend %link-shop-muted;
      display: none;
      position: absolute;
      top: 5.5rem;
      left: 0;

      @include media-breakpoint-up(md) {
        top: 2.5rem;
      }

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: transparent;
        color: $color-link-muted-hover;
      }
    }

    .cart-checkout-button-back {
      @extend .btn-secondary;
    }
  }

  .cart-lineitems-shopping-button {
    @extend .btn-primary;
  }

  .promo-code {
    padding: 1rem;
    margin-top: 3rem;
    background-color: transparentize($color-background-shop, 0.5);

    .promo-code-heading {
      margin-bottom: 0.5rem;
      @extend %text-shop-subheading;
    }

    .promo-code-input-box {
      @extend %input-shop;
      background-color: $color-background-body;
    }

    .promo-code-cta,
    .text-danger {
      margin-top: 0.5rem;
    }

    .promo-code-coupon-container {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $color-border-primary;
      color: $color-link-primary;
      font-size: $font-size-body-l;
      font-weight: bold;
    }

    .promo-code-coupon-subheading {
      margin-bottom: 0.5rem;
      color: $color-dovegray;
      font-size: $font-size-body-s;
      font-style: italic;
    }

    .promo-code-coupon-container,
    .promo-code-line-container {
      display: flex;
      justify-content: space-between;
    }

    .promo-code-line-cta-remove {
      padding: 0;
      font-size: $font-size-body-s;
    }

    .promo-code-divider,
    .promo-code-coupon-price {
      display: none;
    }
  }
  .cart-line-item-error {
    @extend %error-alert;
    padding: 0.5rem 0.75rem;
    @include add-icon($msv-Warning);
    margin-top: 0.75rem;
    margin-bottom: 0;
    &::before {
      margin-right: 0.5rem;
    }
  }
  .msc-alert-danger {
    @extend %error-alert;
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    font-weight: normal;
    p {
      margin: 0;
      @include add-icon($msv-Warning);
      &::before {
        margin-right: 0.5rem;
      }
    }
  }
  .msc-cartline-wraper {
    table {
      width: 100%;
      th{
                font-weight: inherit;
            }
    }
  }
}

.msc-cart-line__add-to-wishlist {
  display: none;
}

//empty cart
.msc-cart__empty-cart {
  .msc-cart__btn-backtoshopping {
    @extend %btn-default;
    @extend .btn-primary;
  }
  p {
    font-size: $font-size-body-m;
  }
}

.ms-text-block.cart-content {
  padding: 0 30px;
  p {
    font-size: $font-size-body-m;
    line-height: 1.5;
  }
}
