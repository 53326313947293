// INDEX OF FADING EXITS

@import 
  "_fadeOut",
  "_fadeOutDown",
  "_fadeOutDownBig",
  "_fadeOutLeft",
  "_fadeOutLeftBig",
  "_fadeOutRight",
  "_fadeOutRightBig",
  "_fadeOutUp",
  "_fadeOutUpBig";
  