$payment-instrument-min-height: 35px;
$payment-instrument-margin: -0.5rem;
$payment-instrument-loading-size: 100%;
$msv-checkout-payment-circular-size: 25px !important;
.ms-checkout-payment-instrument {
  position: relative;
  min-height: $payment-instrument-min-height;
  margin-left: $payment-instrument-margin;
  &__loading-background {
    display: flex;
    height: $payment-instrument-loading-size;
    width: $payment-instrument-loading-size;
    flex-direction: column;
    padding: 0.5rem;
    .waiting {
      display: block;
      margin: auto;
    }
    .msc-waiting-circular {
      width: $msv-checkout-payment-circular-size;
      height: $msv-checkout-payment-circular-size;
    }
  }
  &__show {
    padding: 0 0.5rem 1.5rem;
    margin-top: 2rem;
    p {
      margin-bottom: 0;
    }
  }
  &__card-title {
    @extend %text-shop-heading-secondary;
    font-size: $font-size-body-m;
    font-weight: bold;
  }
  &__iframe {
    border: 0;
  }
  &__add {
    &.is-fetching {
      opacity: 0;
    }
  }
  &__error {
    &-title {
      display: none;
    }
    &-message {
      @extend %error-alert;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0.75rem;
    }
  }
  .checkout-payment-instrument {
    &__iframe {
      border: 0;
    }
  }
  &__card-type-id {
    display: none;
  }
}

// Paypal ready styling
.ms-checkout-payment-instrument-paypal .ms-checkout-payment-instrument {
  &__card-type-id {
    display: block;
  }
}