// Affects the entire list of ratings
.acclaim-list {
  padding: 1rem 0;
  color: $color-mineshaft;
}

// Style for every acclaim entry
.acclaim-entry {
  display: inline-block;
  min-width: 140px;
  min-height: 104px;
  margin-right: 12px;
}

// Style for the rating value
.acclaim-rating {
  min-height: 23px;
  color: $color-mineshaft;
  font-family: $font-family-heading-shop;
  font-size: $font-size-heading-shop-m;
  line-height: 0.9;
  text-transform: lowercase;
}

// Style for the reviewer / rating source
.acclaim-source {
  display: block;
  font-size: $font-size-link-s;
  text-transform: uppercase;
  font-family: $font-family-heading-shop;
}

// Style for the review date
.acclaim-date {
  font-size: $font-size-body-s;
  font-style: italic;
  line-height: 1;
}
