.content-marketing,
.content-marketing-mailing-list {
  max-width: 1400px;
  margin: auto;

  h1,
  .h1 {
    @extend %text-marketing-heading-primary;
    margin-bottom: 2rem;
  }

  h2,
  .h2 {
    @extend %text-marketing-heading-secondary;
  }

  h3,
  .h3 {
    @extend %text-marketing-heading-tertiary;
    margin-bottom: 0.25rem;
  }

  h4,
  .h4 {
    @extend %text-marketing-bold;
  }

  h5,
  .h5 {
    @extend %text-marketing-subheading;
    margin-bottom: 1.5rem;
  }

  .btn-marketing {
    @extend %btn-marketing;
  }

  .link-marketing {
    @extend %link-marketing;
  }

  > .row {
    margin: 0;
  }

  > .row > .col-auto {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 50%;
      padding: 0;
    }

    &:first-child {
      padding: 0;
      background-color: $color-background-tertiary;

      .row {
        margin: 0;
      }

      .col-12 {
        width: 100%;
        padding: 0;
      }

      img {
        width: 100%;
        height: 50vw;
        object-fit: cover;
        object-position: center 33%;
      }

      .hero-text-alignment-container,
      .ms-content-block__details {
        display: none;
      }
    }

    &:last-child {
      padding: 2rem;

      @include media-breakpoint-up(sm) {
        width: 80%;
        padding: 4rem 2rem;
        margin: 0 auto;
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
        max-width: $max-marketing-page-width / 2;
        padding: 10rem 0 4rem 4rem;
        margin: 0;
      }

      .hero,
      .ms-content-block {
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: auto;
        }

        .hero-text-alignment-container,
        .ms-content-block__details {
          display: none;
        }
      }

      .container-with-header-header {
        @include media-breakpoint-up(lg) {
          margin-top: 3rem;
        }
      }

      .content-block-with-title-text {
        line-height: 1.75;
      }

      .content-rich-block,
      .content-rich-item,
      .ms-text-block {
        padding: 0;
      }

      .hero-cta a,
      .ms-content-block__cta a .hero-cta button,
      .ms-content-block__cta button {
        @extend %link-marketing;
      }

      .content-marketing-box {
        text-decoration: none;
      }

      .content-marketing-box > div {
        @extend %box-marketing;

        &:hover,
        &:focus,
        &:active {
          background-color: $color-background-body-accent;
        }

        > .row {
          margin: 0;
        }

        .col {
          padding: 0;
        }

        .col:first-child {
          max-width: 40%;
        }

        .col:last-child {
          display: flex;
          align-items: center;

          h1,
          .h1 {
            display: none;
          }

          p {
            @extend %text-marketing-subheading;
            margin: 0 0 0 1rem;
            font-size: $font-size-body-s;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      .content-marketing-box.smallfont > div {
        .col:last-child {
          p {
            font-size: 11.5px;
          }
        }
      }
      .youtube-modal-clickable {
        @extend %box-marketing;
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          background-color: $color-background-body-accent;
        }

        img {
          width: 40%;
          height: 40%;
        }

        .youtube-modal-textblock {
          display: flex;
          align-items: center;
          min-width: 60%;
          padding-left: 1rem;

          p {
            @extend %text-marketing-subheading;
            margin: 0;
            font-size: 11.5px;
          }
        }

        .youtube-modal-textblock:only-child {
          min-width: 100%;
          padding-left: 0;
        }
      }
    }
  }
}
.container .row .col-12 {
  padding: 0px;
}

.home-marketing {
  .header-container .d-user-action .call-to-action__flex-container {
    @include media-breakpoint-up(lg) {
      color: $color-white;
      text-shadow: 0 1px 5px $color-black;
    }
  }

  #main {
    @include media-breakpoint-up(lg) {
      margin: -150px auto -100px;
    }
  }

  .carousel,
  .msc-carousel {
    max-width: 1300px;
    padding: 0;
    margin: auto;
  }

  .carousel-indicators,
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .hero-marketing-carousel,
  .msc-carousel__item {
    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
      object-position: center -100px;

      @include breakpoint(md) {
        height: 600px;
        object-position: center -110px;
      }

      @include breakpoint(lg) {
        height: 80vh;
        min-height: 800px;
        object-position: center top;
      }
    }

    .hero,
    .ms-content-block {
      position: relative;
      height: 300px;

      @include media-breakpoint-up(md) {
        height: 400px;
      }

      @include media-breakpoint-up(lg) {
        height: 80vh;
        min-height: 800px;
      }
    }

    .hero-text-alignment-container,
    .ms-content-block__details {
      $hero-promo-width: 283px;
      $hero-promo-height: 212px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      width: $hero-promo-width * 0.6;
      height: $hero-promo-height * 0.6;
      padding: 1rem;
      background-image: $asset-background-hero-promo;
      background-size: contain;
      font-size: 11.5px;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: $hero-promo-width * 0.7;
        height: $hero-promo-height * 0.7;
        padding: 1rem 2rem;
      }

      @include media-breakpoint-up(lg) {
        bottom: 20%;
        width: $hero-promo-width * 0.8;
        height: $hero-promo-height * 0.8;
      }

      @include media-breakpoint-up(xl) {
        width: $hero-promo-width;
        height: $hero-promo-height;
      }

      p {
        margin-bottom: 0.25rem;
        font-size: 11.5px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 1rem;
          margin-top: 0.5rem;
        }
      }
    }

    .hero-cta button,
    .hero-cta a,
    .ms-content-block__cta button,
    .ms-content-block__cta a {
      @extend %link-marketing;
    }
  }
}

.content-marketing-vineyard-map .hero,
.content-marketing-vineyard-map .ms-content-block {
  max-width: 1100px;
  margin: 0;

  @include media-breakpoint-up(lg) {
    margin: 5.5rem auto 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  .hero-text-alignment-container,
  .ms-content-block__details {
    display: none;
  }
}

.content-marketing-gallery {
  .header-container .d-user-action .call-to-action__flex-container {
    @include media-breakpoint-up(lg) {
      color: $color-white;
      text-shadow: 0 1px 5px $color-black;
    }
  }

  .carousel,
  .msc-carousel {
    max-width: 1300px;
    padding: 0;
    margin: auto;

    .carousel-indicators,
    .msc-carousel__indicators,
    .hero-text-alignment-container,
    .ms-content-block__details {
      display: none;
    }

    img {
      width: 100%;
      height: 40vw;
      min-height: 300px;
      max-height: 800px;
      object-fit: cover;
    }

    .hero,
    .ms-content-block {
      position: relative;
      height: 40vw;
      min-height: 300px;
      max-height: 800px;
    }
  }
}

.content-marketing-timeline {
  #main {
    max-width: $max-marketing-page-width;
    margin: auto;

    @include media-breakpoint-up(lg) {
      width: $page-width;
      margin: 2rem auto 0;
    }

    > .row {
      padding: 2rem;
      margin: 0;

      @include media-breakpoint-up(lg) {
        padding: 2rem 0;
      }

      &:nth-child(2n) {
        background-color: transparentize($color-background-body-accent, 0.7);
      }

      &:not(:first-child) {
        border-top: 1px solid $color-border-primary;
      }

      > .col {
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(lg) {
          flex: 1 1 50%;
          padding: 0 2rem;
        }
      }

      &:nth-child(2n) {
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
      }

      &:nth-child(2n-1) > .col:last-child,
      &:nth-child(2n) > .col:first-child {
        justify-content: flex-start;
        margin-top: 1rem;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }

      .content-with-media-text {
        width: 100%;
      }

      h4,
      .h4 {
        @extend %text-marketing-date;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .youtube-modal-button {
        @extend %link-marketing;
        color: $color-mineshaft;
        font-size: $font-size-body-xl;
        text-transform: lowercase;

        &:hover,
        &:focus,
        &:active {
          color: $color-link-primary-hover;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.content-marketing-responsibility img {
  max-width: 140px;
  height: auto;
}

.MsoTableGrid {
  margin-bottom: 1rem;
  font-size: 11.5px;
  line-height: 1.4;

  td {
    border: 1px solid transparentize($color-border-primary, 0.65);
    width: 50%;
    vertical-align: top;
    padding: 0.25rem 0.4rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.ms-content-block__image {
  img {
    width: 100%;
    height: auto;
  }
}

.home-marketing {
  .msc-carousel {
    .ms-content-block__image {
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
        object-position: center -100px;
        @include breakpoint(md) {
          height: 600px;
          object-position: center -110px;
        }
        @include breakpoint(lg) {
          height: 80vh;
          min-height: 800px;
          object-position: center top;
        }
        .msc-carousel__inner .msc-carousel__item .ms-content-block .ms-content-block__details {
          display: none;
        }
      }
      .marketing-map-text {
        padding-left: 0px;
      }
      .home-marketing {
        .msc-carousel {
          .ms-content-block__image {
            img {
              width: 100%;
              height: 450px;
              object-fit: cover;
              object-position: center -100px;
              @include breakpoint(md) {
                height: 600px;
                object-position: center -110px;
              }
              @include breakpoint(lg) {
                height: 80vh;
                min-height: 800px;
                object-position: center top;
              }
            }
          }
        }
      }
    }
  }
}
.content-marketing-mailing-list {
  h2,
  .h2 {
    color: inherit;
  }
  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-primary;
  }
  .text-input-group {
    label {
      margin-bottom: 0;
    }

    input.form-control {
      height: calc(1.5em + 0.75rem + 2px);
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      display: block;
      width: 100%;
      border: 1px solid $color-mineshaft;
      @extend %input-shop;
      &:focus {
        box-shadow: 0 0 0 0.2rem $color-zeus;
      }
    }
  }

  .text-input-validation-error {
    @extend %error-alert;
    display: block;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    font-size: 14px;
  }
  .mailing-list-success-message {
    margin-top: 0.5rem;

    p {
      display: inline-block;
    }
  }
}
