.shop.buyboxlite {
  .col-12 {
    padding: 0;
  }

  #renderPage header {
    margin: 0;
  }

  #main {
    width: 100%;
    margin: 0;

    .ms-buybox {
      max-width: 961px;
      padding: 0;
      margin: 0 -15px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      &__media-gallery {
        @extend %col-properties;
        max-width: 28%;
        padding: 0;
        margin: 0;
        border: 0;

        .ms-media-gallery {
          @extend %col-properties;
          padding: 0;
          margin: 0;
          border: 0;
          padding-left: 8px;
        }
      }
      &__content {
        margin: 0;
        padding-left: 8px;
        max-width: 72%;
        @include media-breakpoint-up(md) {
          max-width: 500px;
        }
      }
      &__product-title {
        width: 100%;
        color: $color-kumera;
      }
      &__add-to-cart-container {
        .msc-add-to-cart {
          @extend %btn-default;
          @extend .btn-primary;
          line-height: 1;
          position: static;
          &:before {
            display: none;
          }
        }
      }
    }
    .product-attribute {
      &-apellation-value {
        letter-spacing: 0;
        text-align: left;
        margin: 0;
        font-family: $font-family-heading-shop;
        font-size: $font-size-body-m;
        color: $color-dovegray;
        line-height: 1.5;
      }
      &-nv {
        display: block;
      }
    }

    .ms-media-gallery img {
      max-width: 150px;
      max-height: 387px;
    }

    .product-link {
      margin-top: 1rem;
      display: block;
    }

    .product-link-cta {
      display: block;
      font-size: 13px;
    }

    .media-gallery-carousel {
      max-width: 100%;
    }

    .product-title-heading {
      width: 100%;
    }

    .product-attribute-nv {
      padding-top: 0.5rem;
    }

    .sku-selector-container {
      padding: 0;
      margin: 5rem 0 1rem;
      border-bottom: 1px solid $color-border-primary;
      .table {
        width: 100%;
        @include media-breakpoint-up(md) {
          table-layout: fixed;
        }
      }

      .table-responsive .table {
        background-color: transparent;
        display: table;

        th {
          margin: 0;
          text-align: left;
        }

        tbody {
          display: table-header-group;
        }

        thead {
          display: table-header-group;
        }

        tr {
          display: table-row;
        }
      }

      .sku-selector-product-title {
        width: 180px;
      }

      .sku-selector-product-quantity {
        height: auto;
        justify-content: flex-start;
      }

      .product-price input {
        width: 100%;
        text-align: left;
      }

      .product-price.keyed-in {
        margin-top: -5px;
      }

      .quantity,
      .msc-quantity-input {
        background-color: $color-input-background;
        width: 55px;
      }

      .product-add-to-cart {
        padding: 0;

        .call-to-action__glyph {
          display: none;
        }

        p {
          margin: -0.5rem;
          font-size: $font-size-body-xxs - 1px;
        }

        .alert {
          top: -1.5rem;
          width: 100%;
          padding: 0.25rem 0.75rem;
        }
      }
      .msc-alert-danger {
        padding: 0.25rem 0.75rem;
        line-height: 1.5;
        margin: 0 0 1rem 0;
        left: 0;
        top: -4.5rem;
        @include media-breakpoint-up(md) {
          top: -3.2rem;
        }
      }
    }

    .message-alert {
      padding: 0;
      margin: 0;
    }

    .help-text-container {
      padding: 0 15px;
      font-size: 11.5px;
      color: $color-dovegray;
    }
  }

  #renderPage footer {
    margin: 0;
  }
  .acclaim-list {
    .acclaim-date {
      line-height: 0.9;
      font-size: 13px;
      font-family: $font-family-heading-shop;
    }
  }
}

.shop .msc-modal__content {
  border: 0;
}
.shop.modal-open {
  .msc-modal {
    &__header {
      @include media-breakpoint-up(xl) {
        padding-bottom: 0;
      }
    }
    &__dialog {
      width: 100%;
      height: 100%;
      @include media-breakpoint-up(md) {
        width: initial;
      }
      @include media-breakpoint-up(lg) {
        height: 90vh;
        margin-top: -1.9rem;
      }
    }
    &__content {
      .product-iframe {
        width: 100%;
        height: 600px;
        @include media-breakpoint-up(md) {
          width: 760px;
          height: 610px;
        }
        .iframe-module-content {
          height: 100%;
        }
      }
    }
  }
}
