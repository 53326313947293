%shop-container {
  max-width: $max-shop-page-width;
  padding: 1rem;
  background-color: $color-background-primary;

  @include media-breakpoint-up(sm) {
    width: $page-width;
    padding: 1.5rem 2rem;
  }
}

.shop {
  min-width: calc(347px + 2rem);
  background-image: $asset-background-shop;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-color: $color-background-shop;
  color: $color-text-primary;
  font-size: $font-size-body-m;
  line-height: 1.5;

  #renderPage .ms-header {
    margin-bottom: 1rem;
    &__container {
      max-width: $max-shop-page-width;
      margin: 0 auto;
    }
    .msc-cart-icon__count {
      line-height: 1.5;
      @include breakpoint(lg) {
        color: $color-merino;
        text-shadow: 0 1px 5px #000;
      }
    }
    
    .ms-search__icon {
      line-height: 1.5;
      &-text {
        @include breakpoint(lg) {
          color: $color-merino;
          text-shadow: 0 1px 5px #000;
        }
      }
    }
    .ms-header__signin-button {
      text-decoration: underline;
      @include breakpoint(lg) {
        color: $color-merino !important;
        text-shadow: 0 1px 5px #000;
        &:hover,
        &:active{
          text-decoration: none;
        }
        &:focus{
          border: 2px solid $color-white;
          text-decoration: none;
        }
      }
    }
    // TODO: text decoration for signin button
  }

  #main {
    @extend %shop-container;
    margin-top: 2rem;

    @include media-breakpoint-up(sm) {
      margin: 2rem auto;
    }
    @include media-breakpoint-up(lg){
      padding-top: 3rem;
      margin: -1rem auto 2rem;
    }
  }

  #subfooter {
    @extend %shop-container;
    margin-top: -1px;

    @include media-breakpoint-up(sm) {
      margin: -3rem auto 2rem;
    }
  }

  .hero,
  .ms-content-block {
    margin-bottom: 1rem;

    > div:not(.ms-content-block__image) {
      padding: 0.4rem;
    }

    .theme-dark {
      background-color: $color-background-tertiary;

      .hero-heading,
      .ms-content-block__title {
        color: $color-heading-secondary;
      }
    }

    .hero-heading,
    .ms-content-block__title {
      margin-bottom: 0.2rem;
      font-size: $font-size-heading-shop-xs;
      text-align: center;
      color: $color-heading-primary;
    }

    p {
      margin: 0;
      color: $color-text-tertiary;
      font-size: $font-size-body-s;
      text-align: center;
    }

    a {
      color: $color-link-tertiary;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-tertiary-hover;
      }
    }
  }
}

.sort_and_filter_desktop_view {
  .col-lg-3 {
    display: none;
  }

  .col-lg-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sort_and_filter_mobile_view {
  .sort-and-filter-container {
    display: none;
  }
}

//TODO Refiners

// .products-by-category {
//   .category-title {
//     font-size: $font-size-heading-shop-l;
//   }

//   .category-description {
//     margin-bottom: 1rem;
//     color: $color-heading-title--shop;
//     font-size: $font-size-body-m;
//     line-height: 1.4;
//   }

//   .product-placement__item {
//     position: relative;
//     z-index: 1;
//     display: inline-block;
//     max-width: 317px;
//     height: 330px;
//     padding: 0;
//     margin-right: 2px;
//     border-top: solid 1px $color-silver;
//     vertical-align: top;
//     line-height: 18px;

//     &:focus {
//       outline: none;
//     }

//     a,
//     a:hover,
//     a:visited {
//       text-decoration: none;
//       color: #3e280d;
//       padding: 0;
//     }

//     .product-details {
//       width: 317px;

//       .fa-chevron-right {
//         color: $color-limedoak;
//         float: right;
//         margin: 55px 10px 0px 0px;
//       }

//       .product-details-header {
//         background: $color-pampas;
//         padding: 30px 30px 30px 90px;
//         height: 130px;
//       }

//       .appellation {
//         color: $color-silver;
//         font-size: $font-size-body-xs;
//         font-style: italic;
//         text-transform: uppercase;
//       }

//       .description {
//         display: -webkit-box;
//         -webkit-box-orient: vertical;
//         overflow: hidden;
//         padding: 20px 15px 0 90px;
//         color: $color-silver;
//         font-size: $font-size-body-s;
//         line-height: 1.5;
//         -webkit-line-clamp: 4;
//       }

//       .acclaim-list {
//         padding: 8px 0 0 90px;
//         max-width: 240px;
//         min-height: 85px;
//       }

//       .acclaim-entry {
//         float: left;
//         padding-top: 10px;
//       }
//     }

//     .product-placement__item-image {
//       display: block;
//       margin: auto;
//       position: absolute;
//       bottom: 52px;
//       left: 18px;
//       max-width: 60px;
//       max-height: 260px;
//     }

//     .product-placement__item-title {
//       top: 0;
//       max-height: 60px;
//       overflow: hidden;
//       color: $color-limedoak;
//       font-family: $font-family-heading;
//       font-size: $font-size-heading-shop-xs;
//       text-transform: uppercase;

//       &:hover,
//       &:focus,
//       &:active {
//         color: $color-limedoak;
//       }
//     }

//     .product-lightbox {
//       display: none;
//       position: absolute;
//       bottom: 50px;
//       right: 15px;

//       @include media-breakpoint-up(sm) {
//         display: block;
//       }

//       .launch-lightbox-button {
//         width: 55px;
//         text-align: center;
//         border: 1px solid $color-silver;
//         padding: 3px 0 2px;
//         background-color: $color-pampas;
//         color: $color-limedoak;
//         font-size: $font-size-link-s;

//         &:hover,
//         &:focus,
//         &:active {
//           box-shadow: none;
//           border-color: $color-silver;
//           background-color: $color-pampas;
//           color: $color-limedoak;
//         }
//       }
//     }
//   }
// }
