$breadcrumb-padding-sm: 2rem 2.5rem 0 2.5rem;
$breadcrumb-padding-md: 0;
$breadcrumb-margin-top-md: 4rem;
$breadcrumb-margin-bottom-md: -4rem;
$breadcrumb-margin-top-lg: 6rem;
$breadcrumb-margin-bottom-lg: -6rem;
$breadcrumb-cta-margin-left-md: calc(28% + 2.25rem);
$breadcrumb-cta-margin-left-lg: calc(100% - 64% + 2.25rem);
%breadcrumb-link {
  font-family: $font-family-heading-shop;
  font-size: $font-size-link-m;
  line-height: 1;
}

// @FIXME/vo: Change class name when breadcrumb class in custom module has been renamed.
.ms-breadcrumb {
  display: none;
  padding-bottom: 1rem;
  .category-default & {
    line-height: 1.4;
  }

  @include media-breakpoint-up(sm) {
    display: block;
  }

  &-cta {
    @extend %breadcrumb-link;
    vertical-align: initial;
    font-size: $font-size-link-s;
    padding: 0 0.75rem;
    border: 0;
    color: $color-breadcrumb !important;

    &:first-child {
      padding-left: 0;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color-breadcrumb-hover !important;
      border: 1px solid $color-kumera;
      box-shadow: none !important;
    }
    .call-to-action {
      &__glyph {
        display: none;
      }
    }
  }

  &-data {
    @extend %breadcrumb-link;
    display: inline-flex;
  }

  &-data-divider {
    color: $color-breadcrumb;
  }

  &-data-text {
    padding-left: 0.75rem;
    color: $color-breadcrumb-active;
    font-size: $font-size-link-s;
  }
  &-data-separator {
    font-size: $font-size-link-s;
  }
}
//for PDP TODO
// .marketing .breadcrumbcolsolare {
//   @extend %width-marketing-max;
//   @extend %width-marketing-content-l-max;
//   @include media-breakpoint-up(sm) {
//     display: flex;
//     padding: $breadcrumb-padding-sm;
//   }
//   @include media-breakpoint-up(md) {
//     padding: $breadcrumb-padding-md;
//     margin-top: $breadcrumb-margin-top-md;
//     margin-bottom: $breadcrumb-margin-bottom-md;
//   }
//   @include media-breakpoint-up(lg) {
//     margin-top: $breadcrumb-margin-top-lg;
//     margin-bottom: $breadcrumb-margin-bottom-lg;
//   }
//   &-cta,
//   &-data {
//     color: $color-heading-title;
//     font-family: $font-family-heading;
//     font-style: normal;
//     text-transform: uppercase;
//     color: $color-breadcrumb-active--shop;
//     font-size: $font-size-body-xs;
//     z-index: 5;
//   }
//   &-cta {
//     @include media-breakpoint-up(md) {
//       margin-left: $breadcrumb-cta-margin-left-md;
//     }
//     @include media-breakpoint-up(lg) {
//       margin-left: $breadcrumb-cta-margin-left-lg;
//     }
//   }
// }
