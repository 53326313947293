%link-shop {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $color-link-secondary-hover;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
      background-color: transparent;
      color: $color-link-secondary;
  }
}

%link-shop-subfooter {
  @extend %link-shop;
  font-size: $font-size-link-m;
}

%link-shop-muted {
  @extend %link-shop;
  color: $color-link-muted;

  &:hover,
  &:focus,
  &:active {
      color: $color-link-muted-hover;
  }
}

%text-shop-heading-container {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid $color-border-primary;
}

%text-shop-heading-primary {
  color: $color-heading-primary;
  font-family: $font-family-heading-shop;
  font-size: $font-size-heading-shop-m;
  text-transform: uppercase;
}

%text-shop-heading-secondary {
  @extend %text-shop-heading-primary;
  font-size: $font-size-heading-shop-m;
}

%text-shop-heading-tertiary {
  @extend %text-shop-heading-secondary;
  font-size: $font-size-heading-shop-s + 2px;
  text-transform: none;
}

%text-shop-subheading {
  color: $color-dovegray;
  font-family: $font-family-heading-shop;
  font-size: $font-size-heading-shop-xs;
  font-weight: bold;
  text-transform: uppercase;
}

%text-shop-strong {
  color: $color-text-primary;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: none;
}

%input-shop {
  min-height: 30px;
  padding: 0.25rem 0.5rem;
  border: 1px solid $color-input-border;
  border-radius: 0;
  background-color: $color-input-background;
  color: $color-input-text;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
}

%input-shop-qty {
  @extend %input-shop;
  flex: none;
  width: 50px;
  height: 30px;
  padding: 0.25rem;
  text-align: center;
}
%link-nav-primary {
  border: none;
  background-color: transparent;
  color: $color-link-header-hover;
  font-family: $font-family-body;
  font-size: $font-size-body-xs;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
      border: none;
      box-shadow: none;
      background-color: transparent;
      color: $color-link-header;
  }
}

%link-nav-secondary {
  @extend %link-nav-primary;
  color: $color-link-header;
  font-size: $font-size-body-m;
  text-decoration: none;
  transition: none;

  &:hover,
  &:focus,
  &:active {
      color: $color-link-header-hover;
  }
}

%link-nav-tertiary {
  @extend %link-nav-primary;
  color: $color-link-footer;
  font-family: $font-family-link;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
      color: $color-link-footer-hover;
  }
}

%btn-marketing {
  padding: .5rem 1rem;
  border-width: 2px;
  border-color: $color-button-marketing-border;
  background-color: transparentize($color-button-marketing-background, 0.2);
  color: $color-button-marketing-text;
  font-family: $font-family-link;
  font-size: $font-size-link-xs;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 0 4px transparentize($color-black, 0.4);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
      border-color: $color-button-marketing-border-hover;
      box-shadow: none;
      background-color: transparentize($color-button-marketing-background-hover, 0.2);
      color: $color-button-marketing-text-hover;
  }
}

%link-marketing {
  padding: 0;
  border: none;
  background-color: transparent;
  color: $color-link-secondary;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      background-color: transparent;
      color: $color-link-secondary;
      text-decoration: none;
  }
}

%text-marketing-heading-primary {
  font-family: $font-family-body;
  font-size: $font-size-heading-l;
}

%text-marketing-heading-secondary {
  @extend %text-marketing-heading-primary;
  color: $color-text-secondary;
}

%text-marketing-heading-tertiary {
  @extend %text-marketing-heading-primary;
  color: $color-heading-secondary;
  font-size: $font-size-heading-xs;
  font-weight: bold;
  text-transform: uppercase;
}

%text-marketing-subheading {
  padding: 0.5rem 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: $color-border-primary;
  color: $color-heading-primary;
  font-family: $font-family-heading;
  font-size: $font-size-heading-s;
  font-weight: bold;
  text-transform: uppercase;
}

%text-marketing-date {
  color: $color-link-primary;
  font-family: $font-family-heading;
  font-size: $font-size-heading-m;
  border-bottom: 3px double $color-border-primary;
}

%text-marketing-bold {
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  font-weight: bold;
}

%box-marketing {
  padding: 1rem;
  margin: 1rem 0;
  border-width: 4px 0;
  border-style: solid;
  border-color: $color-border-primary;
  background-color: transparentize($color-background-body-accent, 0.5);
  transition: 0.5s all ease;
}


%btn-default {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
//============================================================================

%form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-input-form-control;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid $color-input-form-control-border;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
%form-control-partial {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


%remove-btn-default {
  background: transparent;
  border: 0;
}

%col-properties {
  position: relative;
  width: 100%;
  padding: 0;
  @include media-breakpoint-up(sm) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

%col-auto {
  position: relative;
  width: 100%;
  @include media-breakpoint-up(md) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}

%input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

%btn-default {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

%btn-default-partial {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

%order-summary-border-spacing {
  display: block !important;
  &:after {
    content: " ";
    display: block;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-color: $color-border-primary;
  }
  .msc-order-summary__value {
    float: right;
  }
}

%form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

%form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

%error-alert {
  position: relative;
  padding: $error-alert-padding;
  margin-bottom: $error-alert-margin-bottom;
  border: 1px solid transparent;
  border-radius: $error-alert-border-radius;
  color: $error-alert-color;
  background-color: $error-alert-background-color;
  border-color: $error-alert-border-color;
}

%success-alert {
  @extend %error-alert;
  color: $success-alert-color;
  background-color: $success-alert-background-color;
  border-color: $success-alert-border-color;
}

%warning-alert {
  @extend %error-alert;
  color: $warning-alert-color;
  background-color: $warning-alert-background-color;
  border-color: $warning-alert-border-color;
}

// ORDER DETAILS PLACEHOLDER

%font-content-large {
  font-family: $font-family-heading-landing;
  font-weight: normal;
  font-size: $font-size-body-l;
  line-height: 24px;
  text-transform: none;
}
%font-content-small {
  font-family: $font-family-heading-landing;
  font-weight: normal;
  font-size: $font-size-body-s;
  line-height: 24px;
  text-transform: none;
}
%font-content-product {
  font-family: $font-family-heading-landing;
  font-weight: normal;
  font-size: $font-size-body-m;
}


//Popover - Global
%popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: $popover-max-width;
  font-family: $font-family-popover-default;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: $color-white;
  background-clip: padding-box;
  border: $popover-border;
  $popover-border-radius: 0.3rem;
}

//Submenu Dropdown container
%subnav-container {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.75rem 0;
    margin: 0;
    border: 0;
    border-radius: 0;
  }
}

%subnav-item {
  display: block;
  width: 100%;
  clear: both;
  white-space: nowrap;
}

//Container
%container-default {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    max-width: 540px;
  }
  @include media-breakpoint-up(md) {
    max-width: 720px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 960px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}


//remove native browser styling
%date-picker-input-override {
  -webkit-appearance: none;
  background-image: $asset-calendar-date;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 97.5% 50%;
  cursor: default;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
  }
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    width: 15px;
  }
}

%select-input-override {
  -webkit-appearance: none;
  background-image: $asset-chevron-down;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 95% 50%;
}