h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-heading;
  color: $color-heading-secondary;
}

a {
  color: $color-link-primary;
  text-decoration: underline;
  transition: 0.5s all ease;

  &:hover,
  &:focus,
  &:active {
    color: $color-link-primary-hover;
    text-decoration: none;
  }
}
footer,
.ms-search__autoSuggest {
  ul,
  ol,
  li {
    list-style: none;
  }
}

.shop {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h5,
  .h4,
  .h6 {
    color: $color-heading-primary;
    font-family: $font-family-heading-shop;
  }

  a {
    text-decoration: none;
  }
}
