%btn-shipping-service-default {
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.5;
  display: block;
  width: 100%;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.shop.smallfont {
  font-size: 13px;
}
.shipping-service {
  .container-heading {
    @extend %text-shop-heading-secondary;
    @extend %text-shop-heading-container;
    border-color: $color-border-secondary;
  }

  > .row {
    padding: 0 2rem;

    > div:first-of-type {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 25%;
        width: 25%;
        padding-left: 0;
      }

      .container-with-header-title {
        @extend %text-shop-heading-tertiary;
      }

      .content-block-with-title-title {
        @extend %text-shop-heading-tertiary;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    > div:last-of-type {
      flex: 0 0 100%;
      margin-top: 2rem;

      @include media-breakpoint-up(lg) {
        flex: 1 1 75%;
        width: 75%;
        padding-right: 0;
        margin-top: 0;
      }

      .accordion-header-text h2,
      .accordion-header-text .h2,
      .ms-accordion-header-section-container .ms-accordion-header-section__heading {
        @extend %text-shop-heading-tertiary;
        font-size: 24px;
      }

      .accordion-header,
      .ms-accordion-header-section {
        margin-top: 2rem;

        @include media-breakpoint-up(sm) {
          display: flex;
          justify-content: space-between;
          margin-top: 0;
        }

        &-toggle-all,
        &-toggle-button-container {
          button {
            @include vfi();
            @extend %btn-default-partial;
            @extend %link-shop;
            text-align: center;
            text-transform: lowercase;

            &:first-child {
              margin-right: 0.25rem;
            }

            &:last-child {
              margin-left: 0.25rem;
            }
          }
        }
      }

      .accordion-item .btn,
      .ms-accordion-item button {
        @extend %btn-shipping-service-default;
        @extend %text-shop-subheading;
        padding: 1rem 0 0;
        margin: 2rem 0;
        border-top: 1px solid $color-tana;
        border-radius: 0;
        font-family: $font-family-heading-shop;
        font-size: 18px;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
        text-transform: none;

        &:focus {
          box-shadow: none;
        }
      }

      .accordion-item .content-rich-block,
      .accordion-item .content-rich-item,
      .ms-accordion-item .content-rich-block,
      .ms-accordion-item .content-rich-item {
        padding: 0;
      }
    }
  }

  .container-with-header-title {
    margin-bottom: 2rem;
  }

  .content-block-with-title:not(:first-child) {
    margin-top: 1rem;
  }
}

.shipping-map-list-item-qty {
  margin-left: 0.4rem;
}
