$promo-wrapper-padding: 1rem;
$promo-wrapper-margin-top: 3rem;
$promo-button-margin-top: 0.5rem;
.promo-code-wrap {
  padding: $promo-wrapper-padding;
  margin-top: $promo-wrapper-margin-top;
  background-color: transparentize($color-whitelinen, 0.5);
  P {
    margin-bottom: 0;
  }
  .msc-alert-danger {
    @include add-icon($msv-Warning);
    margin-top: $promo-button-margin-top;
    &::before {
      margin-right: .5rem;
    }
  }
  .msc-promo-code__input-box {
    @extend %input-shop;
    background-color: $color-white;
    border: 1px solid $color-mineshaft;
  }
}


.msc-promo-code {
  &__applied-code-header {
    @include font-content-l();
  }
  &__line-container {
    display: flex;
    justify-content: space-between;
  }

  &-heading {
    margin-bottom: 0.5rem;
    @extend %text-shop-subheading;
    color: $color-black;
  }

  &__line-value {
    display: block;
  }

  &__input-box {
    @extend %form-control;
  }
  &__apply-btn {
    @extend %btn-default;
    @extend .btn-primary;
    margin-top: $promo-button-margin-top;
  }
  &__applied {
    &-heading {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $color-border-primary;
      color: $color-link-secondary;
      font-size: $font-size-body-s;
      font-weight: bold;
    }
    &-subheading {
      margin-bottom: 0.5rem;
      color: $color-text-description;
      font-size: 11.5px;
      font-style: italic;
    }
  }
  &__line-value-code {
    margin-right: 0.25rem;
    font-size: 13px;
  }
  &__line-value-price {
    font-size: 13px;
    font-weight: bold;
  }
  &__line__btn-remove {
    @extend %link-shop;
    font-size: 11.5px;
  }
}