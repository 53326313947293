$buttons-padding-mobile: 0.3rem;
$buttons-padding-desktop: 0.315rem 0.6rem;
$buttons-padding-xs-right: 0.35rem;
$header-height: 125px;
$header-float-height: 65px;
//Signin Popover Variables
$popover-margin-top: 0.5rem;
$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-margin: 0 0.3rem;
$popover-arrow-top-position: calc(-0.5rem - 1px);
$popover-arrow-border-width: 0 0.5rem 0.5rem 0.5rem;
$popover-arrow-border-color: rgba(0, 0, 0, 0.25);

%text-align {
  text-align: right;
  @media (min-width: $breakpoint-xxs) {
    text-align: initial;
  }
}
%topbar-buttons-style {
  font-size: $font-size-body-m;
  color: $color-link-primary;
  text-transform: capitalize;
  .home-marketing & {
    &:hover,
    &:focus,
    &:active {
      color: $color-white;
    }
  }
}
.ms-header {
  //Header Container
  &__container {
    @include clearfix();
    position: relative;
    background-color: $color-background-secondary;

    @include media-breakpoint-up(lg) {
      // padding: 0 3rem;
      background-color: transparent;
      background-image: $asset-background-header;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  //Header Topbar
  &__topbar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 960px;
    padding: 0.25rem 0 0;

    margin: auto;
    background-color: $color-background-secondary;

    @media (min-width: $breakpoint-xxs) {
      flex-direction: row;
      max-height: 43px;
    }
    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }

    //Header LOGO
    .ms-header__logo {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      position: absolute;
      padding: 0 15px;
      top: 20px;
      left: 0px;
      a {
        display: block;
        border: 0;
      }
      img {
        height: auto;
      }
    }
  }
  //Logo adjustment on mobile/tab view
  .mobile-vp {
    position: relative;
    .ms-header__logo {
      position: absolute;
      top: 0.25rem;
      line-height: 1.4;
      left: 0;
      padding: 0 15px;
      @include media-breakpoint-up(sm) {
        left: 0.5rem;
        line-height: 1.5;
        top: 0rem;
        transform: translateY(-30%);
      }
      @include media-breakpoint-up(lg) {
        padding: 0;
      }
      a {
        border: 0;
      }
      img {
        max-width: 120px;
        height: auto;
        @include breakpoint(sm) {
          max-width: 170px;
        }
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  //cart
  .msc-cart-icon {
    @extend %text-align;
    @extend %btn-default;
    line-height: 1.3;
    @media (min-width: $breakpoint-xxs) {
      line-height: 1.5;
    }
    border: 0;
    &__count {
      @extend %topbar-buttons-style;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      line-height: 1.5;
      font-size: 14px;
      font-family: $font-family-heading-shop;
      .home-marketing &,
      .content-marketing-gallery & {
        @include media-breakpoint-up(lg) {
          color: $color-white;
          text-shadow: 0 1px 5px #000;
        }
      }

      &::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-shopping-cart);
        margin-right: 0.35rem;
        font-size: $font-size-icon-s;
      }
    }
  }

  //Signin Button
  &__account-info {
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
    @extend %text-align;
    padding-top: 0;
  }
  &__signin-button,
  &__profile-button {
    @include vfi();
    @extend %btn-default;
    @extend %topbar-buttons-style;
    border: 0;
    line-height: 1.5;
    font-size: 14px;
    text-transform: capitalize;
    font-family: $font-family-heading-shop;
    text-decoration: underline;
    color: $color-link-header;
    &:hover,
    &:active {
      text-decoration: none;
      color: $color-link-header;
    }
    &:focus {
      border: 2px solid $color-white;
      text-decoration: none;
    }
    .home-marketing &,
    .content-marketing-gallery &,
    .shop & {
      @include media-breakpoint-up(lg) {
        color: $color-white !important;
        text-shadow: 0 1px 5px $color-black;
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          color: $color-white !important;
        }
      }
    }
  }
}

//Search Button
.ms-search {
  @extend %col-properties;
  @extend %text-align;
  @include clearfix();
  padding: 0;
  width: 100%;
  max-width: none;
  @media (min-width: $breakpoint-xxs) {
    flex: auto;
    width: auto;
    max-width: 180px;
  }
  .bx-hide {
    display: none;
  }
  &__icon {
    @include vfi();
    @extend %btn-default;
    font-size: 14px;

    font-family: $font-family-heading-shop;
    @media (min-width: $breakpoint-xxs) {
      line-height: 1.5;
    }
    border: 0;
    float: right;
  }
  &__icon-text {
    @extend %topbar-buttons-style;
    text-decoration: underline;
    font-size: 14px;
    &::before {
      @include msv-icon();
      content: $msv-magnifying-glass;
      margin-right: 0.35rem;
      font-size: $font-size-icon-s;
    }
    &:hover {
      text-decoration: none;
    }
    .home-marketing &,
    .content-marketing-gallery & {
      @include media-breakpoint-up(lg) {
        color: $color-white;
        text-shadow: 0 1px 5px #000;
      }
    }
  }
}

.msc-popover {
  @extend %popover;
  margin-top: $popover-margin-top;
  text-align: center;
  .msc-arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: $popover-arrow-margin;
    top: $popover-arrow-top-position;
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
    &:before {
      top: 0;
      border-width: $popover-arrow-border-width;
      border-bottom-color: $popover-arrow-border-color;
    }
    &:after {
      top: 1px;
      border-width: $popover-arrow-border-width;
      border-bottom-color: $color-white;
    }
  }
}
//signin popover
.ms-header__account-info {
  .msc-popover-inner {
    padding: 0.5rem 0.75rem;
    color: $popover-body-color;
    display: flex;
    flex-direction: column;

    .ms-signin-info__account-link-button,
    .ms-header__signout-button {
      @extend %btn-default;
      border: none;
      background-color: transparent;
      color: $color-link-header;
      font-family: $font-family-body;
      font-size: $font-size-body-m;
      text-decoration: none;
      transition: none;
      text-transform: capitalize;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        border: none;
        box-shadow: none;
        background-color: transparent;
        color: $color-link-header-hover;
      }
    }
  }
}

//Account Header Spacing Fix
.account-header {
  .ms-header__topbar {
    height: 37px;
    background: transparent;
  }
}
