$search-result-container-page-item-previous-padding-right: 10px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-page-item-next-padding-right: 4px;
.sort_and_filter_desktop_view {
  .col-lg-3 {
    display: none;
  }
  .row:first-child {
    margin: 0;
    @include media-breakpoint-up(lg) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.sort_and_filter_mobile_view {
  .sort-and-filter-container {
    display: none;
  }
  .products-container {
    padding: 0 15px;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}
.products-by-category,
.ms-search-result-container {
  &__title {
    h2,
    .h2 {
      font-size: $font-size-heading-m;
      text-transform: uppercase;
    }
  }

  .list-unstyled {
    display: grid;
    grid-template-columns: 100%;
    @media (min-width: 840px) {
      grid-template-columns: 50% 50%;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 33.33% 33.33% 33.33%;
    }
  }

  .category-description {
    margin-bottom: 1rem;
    color: $color-dovegray;
    font-size: 11.5px;
    line-height: 1.4;
  }

  .product-placement__item,
  .ms-product-search-result__item {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 100%;
    height: 330px;
    padding: 0;
    margin-right: 2px;
    border-top: solid 1px $color-border-secondary;
    vertical-align: top;
    line-height: 18px;

    &:focus {
      outline: none;
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: #3e280d;
      padding: 0;
    }

    .product-details {
      width: 100%;

      .fa-chevron-right {
        color: $color-link-primary;
        float: right;
        margin: 55px 10px 0px 0px;
        font-size: $font-size-link-landing;
      }

      .product-details-header {
        background-color: $color-background-landing-content;
        padding: 30px;
        height: 130px;
      }

      .appellation {
        display: none;
        color: $color-text-primary;
        font-size: 11.5px;
        text-transform: uppercase;
        font-family: $font-family-heading-shop;
      }

      .description {
        display: none;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 20px 15px 0 90px;
        color: $color-text-primary;
        font-size: 11.5px;
        line-height: 1.7;
        min-height: 94px;
        max-height: 94px;
        -webkit-line-clamp: 4;
      }

      .acclaim-list {
        padding: 8px 0 0 90px;
        max-width: 240px;
        min-height: 85px;
      }

      .acclaim-entry {
        float: left;
        padding-top: 10px;
      }
    }

    .product-placement__item-image,
    img {
      display: block;
      margin: auto;
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      max-height: 184px;
      object-fit: cover;
    }

    .product-type-bottled-wine,
    .product-type-canned-wine,
    .product-type-small-bottle {
      .product-details {
        .product-attribute.appellation {
          display: block;
        }

        .product-attribute.description {
          display: -webkit-box;
        }

        .product-details-header {
          padding-left: 90px;
        }
      }

      .product-placement__item-image,
      img {
        bottom: 52px;
        width: auto;
        object-fit: fill;
      }
    }

    .product-type-bottled-wine {
      .product-placement__item-image,
      img {
        max-width: 60px;
        max-height: 260px;
        left: 18px;
      }
    }

    .product-type-canned-wine {
      .product-placement__item-image,
      img {
        max-width: 57px;
        max-height: 120px;
        left: 18px;
      }
    }

    .product-type-small-bottle {
      .product-placement__item-image,
      img {
        max-width: 40px;
        max-height: 135px;
        left: 26px;
      }
    }

    .product-type-assembly {
      .product-placement__item-image,
      img {
        object-fit: contain;
      }

      .product-details-header {
        padding: 48px 30px 30px 90px;
      }
      // svv - from override 11/15/2022
      .product-details {
        padding: unset;
      }
    }

    .product-placement__item-title {
      top: 0;
      max-height: 80px;
      overflow: hidden;
      color: $color-link-primary;
      font-family: $font-family-heading-shop;
      font-size: $font-size-heading-shop-s;
      font-weight: normal;
      line-height: 1.3;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-primary-hover;
      }
    }

    .product-lightbox {
      display: none;
      position: absolute;
      bottom: 50px;
      right: 15px;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      .launch-lightbox-button {
        width: 55px;
        text-align: center;
        border: 1px solid $color-border-secondary;
        padding: 3px 0 2px;
        background-color: $color-input-background;
        color: $color-button-primary-background;
        font-size: $font-size-link-xs;
        font-family: $font-family-heading-shop;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          border-color: $color-border-secondary;
          background-color: $color-input-background;
          color: $color-button-secondary-text-hover;
        }
      }
    }
  }
}
nav.ms-search-result-container__pagination {
  width: 100%;
}

.msc-pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
  width: 100%;
  .ms-search-result__pagination-left {
    margin-right: 4px;
  }
  .ms-search-result__pagination-right {
    margin-left: 4px;
  }
}

.previous.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-previous-padding-right;
  text-align: left;

  .msc-pagination__prev {
    padding-left: 4px;
    display: inline-block;
  }

  .msc-page-link {
    &:before {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-left {
      @include add-icon($msv-ChevronLeft, before);
    }
  }
}

.msc-page-item {
  padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-next-padding-right;
  text-align: right;

  .msc-pagination__next {
    padding-right: 4px;
    display: inline-block;
  }

  .msc-page-link {
    padding-right: 4px;

    &:after {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-right {
      @include add-icon($msv-ChevronRight, after);
    }
  }
}

.msc-page-link {
  @include vfi();
}
