//==============================================================================
// GLOBAL VARIABLES
//==============================================================================
$page-width: 80%;
$max-marketing-page-width: 960px;
$max-shop-page-width: 1025px;
$max-aad-page-width: 600px;
$max-landing-page-width: 1280px;
$max-landing-hero-width: 1440px;
$margin-aad-container: 10rem auto 4rem;
$margin-aad-container-mobile: 2rem auto;

$breakpoint-s: 767px;
$breakpoint-xs: 450px;
$breakpoint-xxs: 300px;
$breakpoint-xl: 1200px;
$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 0px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;
$msv-breakpoint-ipad: 1024px;
$breakpoint-lg: 991px;

$breakpoints: (
    xs: 320px,
    sm: 576px, 
    md: 768px,  
    lg: 992px,     
    xl: 1200px
);

//change rule for min-width mobile first and max-width desktop first approach
@mixin breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}
//example
//@include breakpoint(m, max-width) {}

$wineclub-breakpoints: (
  xs: 280px,
  sm: 375px,
  btn: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

@mixin wc-breakpoint($bp, $rule: min-width) {
  @media screen and (#{$rule}: map-get($wineclub-breakpoints, $bp)) {
    @content;
  }
}