$msv-feature-body-color: $color-dovegray;
$buybox-product-title-size: 34px;
$buybox-font-size: 14px;
$buybox-content-items-padding-top: 20px;
$buybox-content-items-padding-top-small: 12px;
$buybox-margin-top: 2rem;
$buybox-button-height: 32px;
$buybox-quantity-width: 50px;
$buybox-find-in-store-description-padding-bottom: 7px;
$buybox-product-title-line-height: 1.2;
$buybox-quantity-label-line-height: 20px;
$buybox-label-margin-bottom: 5px;
$buybox-title-margin-bottom: 1rem;
$buybox-dropdown-height: 30px;
$buybox-ratings-margin-top: 10px;
$gutter-width: 40px;
$media-gallery-width: 28%;
$buybox-error-alert-top: 0.5rem;
$buybox-error-alert-right: -1rem;
$buybox-error-alert-width: 100%;
$buybox-error-alert-padding: 0.4rem 1rem 0.4rem;

// mixin
@mixin buybox-button {
  width: 100%;
}
.msc-ss-carousel-vert {
  display: none;
}
.ms-buybox {
  @extend %container-default;
  padding-left: 0;
  padding-right: 0;
  padding-top: $buybox-margin-top;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  @include media-breakpoint-up(md) {
    flex-flow: row;
  }
  // content styles
  &__content {
    color: $color-dovegray;
    font-size: 13px;
    flex-grow: 1;
    max-width: 87%;
    @include breakpoint(xs) {
      padding: 0;
    }
    @include breakpoint(md) {
      padding: 0 2rem;
    }
  }

  &__product-attribute-apellation {
    color: $color-dovegray;
    font-size: 14px;
    font-style: italic;
    text-transform: uppercase;
  }

  &__product-title,
  h1,
  .h1 {
    font-size: $buybox-product-title-size;
    line-height: $buybox-product-title-line-height;
    margin-bottom: 0.5rem;
    color: $color-link-primary;
    font-family: $font-family-heading-shop;
  }
  h1,
  .h1 {
    &.ms-buybox__product-title {
      color: $color-link-primary;
    }
  }
  &__dropdown {
    padding-top: 0;
  }
  &__quantity,
  .product-price {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      justify-content: center;
    }
    label {
      margin: 0 !important;
    }
    input {
      color: $color-dovegray;
      background-color: $color-white;
      border: 1px solid $color-background-secondary;
      text-align: left;
      &:focus {
        border: 1px solid $color-silver;
      }

      @media (min-width: $msv-breakpoint-m) {
        background-color: $color-whitelinen;
      }
    }
    .msc-alert {
      display: none !important;
    }
  }
  .product-price input {
    color: $color-dovegray;
    width: auto;
    max-width: 70px;
    height: 30px;
    font-size: 13px;
    border-radius: 0;
    padding: 0.25rem;
    text-align: center;
    text-align: left;
    &:focus {
      border: 1px solid $color-silver;
    }
  }
  .product-attribute-nv-value {
    display: block;
    color: $color-text-primary !important;
  }
  .col-12:nth-child(3) {
    padding: 0;
  }
  .col-12 {
    .product-attribute-nv-value {
      display: block;
      color: $color-mineshaft;
    }
    .product-attribute-nv-name {
      color: $color-codgray;
    }
  }
  &__find-in-store-heading,
  &__find-in-store-description {
    display: none;
  }

  &__ratings-link {
    @include vfi();
    display: inline-block;
  }

  .msc-price__actual,
  .ms_buybox__dropdown-quantity-label,
  .ms_buybox__product-quantity-label-heading {
    color: $color-dovegray;
    font-weight: 600;
    line-height: $buybox-quantity-label-line-height;
  }

  &__media-gallery {
    display: block;
    max-width: 100%;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-border-primary;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 66.66667%;
      flex-basis: $media-gallery-width;
      max-width: $media-gallery-width;
      margin: 0;
      border: none;
    }
    .ms-media-gallery {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      height: auto;
      @include breakpoint(xs) {
        margin: 0 auto;
      }
      .msc-carousel__item {
        img {
          max-width: 100%;
        }
        .non-wine-product {
          height: auto !important;
        }
      }
      .product-link {
        display: none;
      }
    }
  }
  .message-success-text {
    font-size: $font-size-body-m;
    color: $color-mineshaft;
    font-weight: normal;
  }
  .acclaim-list {
    padding: 0;
    .acclaim-entry {
      padding: 1rem 0;
      .acclaim-date {
        line-height: 0.9;
        font-size: 13px;
        font-family: $font-family-heading-shop;
      }
    }
  }
  .msc-no-ratings {
    margin-top: $buybox-ratings-margin-top;
  }
  .msc-dropdown__select {
    @include vfi();
    height: $buybox-dropdown-height;
    width: 100%;
  }

  .msc-quantity-input {
    @include vfi();
    height: $buybox-dropdown-height;
    text-align: center;
    width: $buybox-quantity-width;
    padding: 0.25rem;
    color: $color-dovegray;
    border: 1px solid $color-mineshaft;
    &:focus {
      border: 1px solid $color-silver;
    }
    @include media-breakpoint-up(md) {
      margin: auto;
    }
  }

  &__add-to-cart-container {
    .msc-add-to-cart {
      @include primary-button();
      padding: 0.4rem 0.8rem 0.35rem;
      letter-spacing: 0;
      font-size: $font-size-body-m;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      width: 100%;
      font-family: $font-family-body;
      @include media-breakpoint-down(sm) {
        @include add-icon($msv-ShoppingCart, before, 900);
        &:before {
          margin-right: 0.25rem;
        }
        &:focus {
          @include default-outline;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 0.4rem 0.8rem 0.5rem;
        width: auto;
        border: none;
        background-color: transparent;
        color: $color-link-primary;
        text-shadow: none;
        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active:focus {
          color: $color-link-primary-hover;
          border: none;
          background-color: transparent;
        }
        padding-left: 1rem;
        padding-top: 0.5rem;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        @include add-icon($msv-ShoppingCart, before, 900);
        &:before {
          margin-right: 0.25rem;
        }
        &:focus {
          @include default-outline;
        }
        padding-left: 1rem;
      }
      &.disabled,
      &:disabled {
        border: 1px solid $color-button-disabled-border;
        background-color: $color-button-disabled-background;
        color: $color-button-disabled-text;
        pointer-events: none;
        touch-action: none;
        &::before {
          display: none;
        }
      }
    }
  }

  &__add-to-wishlist-container {
    padding-top: $buybox-content-items-padding-top-small;

    .msc-add-to-wishlist {
      &:after {
        color: tomato;
      }
      border-color: transparent;
      background-color: transparent;
      width: $buybox-button-height;
    }
  }

  &__find-in-store-description {
    padding-bottom: $buybox-find-in-store-description-padding-bottom;
  }

  &__find-in-store {
    padding-top: $buybox-content-items-padding-top;
    .ms-buybox__find-in-store-button {
      @include vfi();

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  //mobile vp 768
  @include breakpoint(xs) {
    &__content {
      max-width: 100%;
    }
    .ms-media-gallery {
      width: 100%;
      .msc-carousel__item {
        img {
          margin: 0 auto;
          height: auto;
          max-height: 387px;
        }
        .non-wine-product {
          height: auto !important;
        }
      }
    }
  }
  @include breakpoint(lg) {
    .ms-media-gallery {
      width: 100%;
      .msc-carousel__item {
        img {
          margin: 0 auto;
          height: auto;
        }
        .non-wine-product {
          height: auto !important;
        }
      }
    }
  }
  .sku-selector-product-title {
    width: auto;

    @media (min-width: $msv-breakpoint-m) {
      width: 30%;
    }
    @media (min-width: $msv-breakpoint-l) {
      width: 40%;
    }
  }
  .product-attribute-apellation {
    color: $color-dovegray;
    font-size: 13px;
    text-transform: uppercase;
    font-family: $font-family-heading-shop;
  }

  label {
    display: block;
    margin-bottom: $buybox-label-margin-bottom;
    font-weight: 600;
  }
  .product-attribute {
    &-download {
      display: flex;
      align-items: center;
      margin: 1rem 0;
      i {
        color: $color-link-primary;
        font-size: 16px;
      }
      .btn-link {
        @extend %text-shop-subheading;
        padding: 0;
        margin-left: 0.5rem;
        border: 0;
        color: $color-link-primary;
        font-weight: bold;

        &:hover,
        &:focus,
        &:active {
          color: $color-link-primary-hover;
        }
      }
    }
    &-nv {
      h4,
      .h4 {
        color: $color-link-primary;
        font-weight: bold;
      }
      &-name {
        @extend %text-shop-subheading;
        margin-top: 1.5rem;
        font-weight: bold;
      }
    }
    &-wine {
      display: flex;
      color: $color-mineshaft;
      &-key {
        margin-right: 0.25rem;
      }
    }
    &-event-description > *:not(:first-child) {
      margin-top: 1rem;
    }
    &-event-description-intro {
      font-size: $font-size-body-m + 2px;
      font-weight: bold;
    }
  }
  .msc-product-attribute-download-cta {
    @include add-icon($msv-arrow-right, before, 900);
    font-size: 12px;
    &:before {
      margin-right: 0.25rem;
      transform: rotate(90deg);
      border: 2px solid tomato;
      border-radius: 50%;
    }
    a {
      &:focus {
        @include default-outline;
      }
    }
    @media (min-width: $msv-breakpoint-m) {
      width: auto;
      border: none;
      background-color: transparent;
      color: tomato;
      text-shadow: none;
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        color: $color-link-primary;
        border: none;
        background-color: transparent;
      }
    }
    @media (min-width: $msv-breakpoint-s) {
      width: auto;
      border: none;
      background-color: transparent;
      color: tomato;
      text-shadow: none;
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        color: $color-link-primary;
        border: none;
        background-color: transparent;
      }
    }
  }
  .sku-selector-container {
    &.no-club {
      border: 0;
    }
    position: relative;
    padding-top: 3.5rem;
    @include media-breakpoint-up(md) {
      padding-top: 5.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }
    margin: 0;
    border-bottom: 1px solid $color-border-secondary;

    .unselectable input {
      pointer-events: none;
      touch-action: none;
      color: $color-button-disabled-text;
      background-color: $color-button-disabled-background;
      border-color: $color-button-disabled-border;
      opacity: 0.85;
    }
  }
  .table-responsive {
    overflow-x: hidden;
    @media (min-width: $msv-breakpoint-m) {
      overflow-x: hidden;
    }
  }
  table {
    display: inline-flex;
    padding: 1rem;
    background-color: $color-whitelinen;
    @media (min-width: $msv-breakpoint-m) {
      display: table;
      width: 100%;
      padding: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      table-layout: fixed;
    }
  }
  thead {
    display: inline-block;
    width: 40%;
    @media (min-width: $msv-breakpoint-m) {
      display: table-header-group;
      width: auto;
    }
  }
  .table {
    th,
    td {
      padding: 0.5rem;
      vertical-align: middle;
    }
    th {
      @extend %text-shop-subheading;
      font-weight: bold;
      text-align: right;
      @include media-breakpoint-up(md) {
        margin-bottom: 0.1rem;
      }
      @include media-breakpoint-up(md) {
        color: $color-codgray;
        text-align: initial;
        margin: 0;
      }
      color: $color-dovegray;
    }
    td {
      color: #212529;
      font-size: 13px;
      font-family: $font-family-body;
      font-weight: 400;
      line-height: 1.65;
      @include media-breakpoint-up(md) {
        line-height: 1;
        &:last-child {
          padding-right: 0;
        }
      }
      &.sku-selector-add-to-cart {
        .msc-add-to-cart {
          position: relative;
          left: -28%;
          @include media-breakpoint-up(sm) {
            left: -33%;
          }
          @include media-breakpoint-up(md) {
            position: static;
            left: 0;
          }
        }
      }
    }
    tr {
      display: inline-grid;
      width: 100%;
      @media (min-width: $msv-breakpoint-m) {
        display: table-row;
        width: auto;
      }
      td,
      th {
        &:first-child {
          @include media-breakpoint-up(md) {
            padding-left: 0;
          }
        }
      }
      .sku-selector-product-title {
        width: auto;

        @include media-breakpoint-up(md) {
          width: 30%;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }
      }
      .sku-selector-product-price,
      .sku-selector-product-quantity {
        @include media-breakpoint-up(md) {
          text-align: center;
        }
      }
      th.sku-selector-product-quantity {
        @include media-breakpoint-up(md) {
          height: 55px;
        }
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0.25rem;
        @include media-breakpoint-up(md) {
          display: block;
          height: auto;
          margin: 0;
        }
      }
    }
    tbody {
      width: 70%;

      @include media-breakpoint-up(sm) {
        width: 60%;
      }

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }
  &__media-gallery {
    .msc-carousel__item {
      height: unset;
      max-height: unset;
      img {
        max-height: 700px;
      }
      .non-wine-product {
        height: auto !important;
      }
    }
  }
  //Alert
  .msc-alert-danger {
    @extend %error-alert;
    display: flex;
    position: absolute;
    right: 0;
    margin: 1.5rem;
    top: -1.8rem;
    left: -1.5rem;
    @include media-breakpoint-up(md) {
      right: $buybox-error-alert-right;
    }
    width: $buybox-error-alert-width;
    padding: $buybox-error-alert-padding;
    font-size: 13px;
    font-weight: normal;
    .msc-alert__header {
      display: inline;
      margin-right: 0.25rem;
      .msi-exclamation-triangle {
        @include add-icon($msv-IncidentTriangle);
        margin-right: 0.4rem;
      }
    }
    .msc-alert__line {
      display: inline;
      margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      .show {
        display: flex;
      }
    }
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}

.buybox-product-details-wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid lightgray;
  .buybox-product-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }
  .ms-buybox__configure {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .ms-buybox__dropdown {
      padding-right: 20px;
    }
  }
}
.product-widget > .row {
  min-height: 175px;
  margin: 2rem 0 0;

  > * {
    flex: 0 0 100%;
    padding: 1.5rem;
    background-color: $color-background-secondary;

    &:first-child:not(:last-child) {
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &:nth-child(2):last-child {
      @include media-breakpoint-up(lg) {
        flex: 0 0 49.5%;
        max-width: 49.5%;
        margin-left: 0.5%;
      }
    }

    &:nth-child(2):not(:last-child),
    &:nth-child(3) {
      @include media-breakpoint-up(lg) {
        flex: 0 0 24.5%;
        max-width: 24.5%;
        margin-left: 0.5%;
      }
    }

    &:not(:first-child) {
      margin-top: 0.25rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &:first-child {
      background-color: $color-link-primary;
    }

    h3,
    .h3 {
      color: $color-background-secondary;
      font-size: $font-size-heading-shop-s + 3px;
      text-transform: uppercase;
    }

    h4,
    .h4 {
      @extend %text-shop-subheading;
      color: $color-link-primary;
      line-height: 1.4;
    }

    h6,
    .h6 {
      color: $color-link-primary;
      font-family: $font-family-body;
      font-size: $font-size-heading-shop-xs;
    }

    p {
      font-size: $font-size-body-xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .hero-cta {
      margin-top: 1rem;

      a,
      button {
        background-color: darken($color-link-primary, 5);
        border-color: darken($color-link-primary, 5);

        &:hover,
        &:focus,
        &:active {
          background-color: $color-link-primary;
          border-color: $color-link-primary;
        }
      }
    }
  }
}
div.ms-buybox__content > div:last-child() {
  padding: 0;
}
.price-width-adjust {
  @media (max-width: $msv-breakpoint-m) {
    line-height: 2.1rem;
    input {
      max-width: 100px;
    }
  }
  @media (min-width: $msv-breakpoint-m) {
    width: 100px;
    margin: 0 auto;
  }
}
.shop:not(.buyboxlite) {
  .merchandise {
    .col-12 {
      &:nth-last-child(2) {
        order: -1;
      }
    }
    display: flex;
    flex-wrap: wrap;
    .ms-buybox__product-title {
      margin-bottom: 1rem;
      order: -2;
    }
    .sku-selector-container {
      padding-top: 4rem;
      width: 100%;
    }
    .msc-alert-danger {
      top: -1rem;
    }
  }
}
