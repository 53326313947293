.message {
  position: relative;
  animation: seconds 1s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;

  &-alert {
    display: flex;
    opacity: 0;
    height: 0;
    animation: cssAnimation 3s;
  }

  @-webkit-keyframes seconds {
    0% {
      opacity: 1;
    }

    100% {
      position: absolute;
      opacity: 0;
      left: -9999px;
    }
  }

  @-moz-keyframes seconds {
    0% {
      opacity: 1;
    }

    100% {
      position: absolute;
      opacity: 0;
      left: -9999px;
    }
  }

  @keyframes seconds {
    0% {
      opacity: 1;
    }

    100% {
      position: absolute;
      opacity: 0;
      left: -9999px;
    }
  }



  @-webkit-keyframes cssAnimation {
    5% {
      opacity: 0;
      height: 0;
    }

    25% {
      opacity: 1;
      height: 100%;
    }

    80% {
      opacity: 1;
      height: 100%;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }

  @-moz-keyframes cssAnimation {
    5% {
      opacity: 0;
      height: 0;
    }

    25% {
      opacity: 1;
      height: 100%;
    }

    80% {
      opacity: 1;
      height: 100%;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }



  @keyframes cssAnimation {
    5% {
      opacity: 0;
      height: 0;
    }

    25% {
      opacity: 1;
      height: 100%;
    }

    80% {
      opacity: 1;
      height: 100%;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }
}
