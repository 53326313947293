.wayin {
  #renderPage {
      height: 0;
      line-height: 0;
      overflow: hidden;
  }

  header {
      margin: 0;
  }

  main {
      padding: 0;
  }
}