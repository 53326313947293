.mailing-list-popup {
  text-align: center;
  padding-top: 1rem;

  .ms-content-block {
    min-height: 0;
  }

  img {
    margin: 1rem auto 0;
    @include media-breakpoint-up(sm) {
      width: auto !important;
    }
  }

  .msc-modal__dialog {
    max-width: 500px !important;
    max-height: 100% !important;
    width: 90%;
    background-color: transparent;
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .msc-modal__content {
    background-color: $color-white;
    border: 0;
    .msc-modal__header {
      padding: 0;
    }

    .msc-modal__body {
      padding: 0 3rem 1rem;
    }
    .msc-modal__close-button {
      right: 12px;
      top: 8px;
      position: relative;
    }
  }

  .mailing-list-form-marketing-description {
    font-size: $font-size-body-s;
    .content-title {
      color: $color-text-muted;
      font-size: 1.5rem;
    }
    p {
      color: $color-text-primary;
    }
  }

  .mailing-list-form-marketing-title,
  .mailing-list-form-marketing-subtitle {
    color: $color-text-primary;
  }
  .mailing-list-success-message p {
    display: inline-block;
  }
  .mailing-list-success-message {
    color: $color-text-primary;
    font-size: $font-size-body-s;
    margin: 1.5rem 0 0.5rem;
    a {
      @extend %btn-default;
      @extend .btn-secondary;
      display: block;
      width: 100%;
      margin: 1.5rem auto 0;
      @media (min-width: $breakpoint-xs) {
        width: 75%;
      }
    }
  }

  .text-input-validation-error {
    @extend %error-alert;
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    font-size: $font-size-body-s;
    line-height: 1.5;
  }

  .text-input-group {
    text-align: left;

    .form-control {
      @extend %form-control-partial;
      @extend %input-shop;
      border: 1px solid $color-mineshaft;
    }

    label {
      margin-bottom: 0.25rem;
      color: $color-dovegray;
      font-weight: bold;
    }
  }

  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-primary;
    margin: 1rem 0 1.25rem;
  }

  .popup-modal-close-text {
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: block;
    color: $color-text-muted;
    font-size: $font-size-body-s;
    cursor: pointer;
  }
}
