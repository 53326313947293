.checkout {
  .msc-waiting {
    flex: none !important;
  }
  .checkout-heading > .row {
    margin: 0;

    .content-block-with-title {
      @extend %text-shop-heading-container;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .content-block-with-title-title {
      @extend %text-shop-heading-primary;
    }

    .content-block-with-title-title,
    .content-block-with-title-text p {
      margin-bottom: 0;
    }
    .content-block-with-title-text p {
      font-size: $font-size-body-m;
    }
  }

  .ms-checkout {
    &__error-message {
      @extend %error-alert;
      width: 100%;
      padding: 0.5rem 0.75rem;
      flex-basis: 100%;
      order: 1;
      margin-bottom: 2rem;
      @include media-breakpoint-up(lg) {
        order: unset;
      }
      @include add-icon($msv-Warning);
      &::before {
        margin-right: 0.5rem;
      }
    }
  }

  .ms-checkout__body {
    padding: 0 15px;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    > div {
      @include media-breakpoint-up(lg) {
        flex: 1 1 50%;
      }
    }

    > .ms-checkout__main {
      margin-top: 3rem;

      @include media-breakpoint-up(lg) {
        padding-right: 1rem;
        margin: 0;
      }
    }

    > .ms-checkout__side {
      @include media-breakpoint-up(lg) {
        padding-left: 1rem;
      }
    }

    .ms-checkout-section-container {
      padding: 0;
    }
  }

  .ms-checkout__guided-card {
    padding: 2rem 0;
    border-bottom: 1px solid $color-border-primary;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: 0;
    }

    &.disabled {
      display: none;
    }

    .ms-checkout__guided-card-title {
      @extend %text-shop-heading-secondary;
      line-height: 1;
      margin-bottom: 0;
      font-size: $font-size-heading-shop-m - 6px;
      letter-spacing: 0.5px;
      @include media-breakpoint-up(sm) {
        font-size: $font-size-heading-shop-m - 4px;
        letter-spacing: 0;
      }
      @include media-breakpoint-up(xl) {
        font-size: $font-size-heading-shop-m;
      }
    }

    .ms-checkout__guided-card-body {
      margin-top: 1rem;

      &.hidden {
        display: none;
      }
    }
    .msc-address-select__button-add {
      @extend %btn-default;
      @extend .btn-secondary;
    }
    .msc-address-select__item {
      position: relative;
      display: block;
      padding-left: 1.25rem;
      margin-top: 0.5rem;
    }

    .ms-checkout__guided-card-btn-edit {
      @include vfi();
      @extend %link-shop;
      float: right;
    }

    .ms-checkout-gift-card__btn-apply {
      @extend %btn-default;
      @extend .btn-primary;
      margin-top: 1rem;
    }
    .msc-address-form__item,
    .checkout-date-of-birth {
      margin-top: 1rem;
      line-height: 1.4;
    }

    .ms-checkout-section-container__item:not(:first-child) {
      margin-bottom: 5px;
    }

    .msc-address-form__label {
      margin-bottom: 0;
      font-size: $font-size-body-m;
    }

    .msc-address-form__input,
      .msc-address-form__dropdown,
      .checkout-guest-profile__input-text, //todo
      .checkout-date-of-birth input,
      .ms-checkout-gift-card__input-text {
      height: calc(1.5em + 0.75rem + 2px);
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      display: block;
      width: 100%;
      @extend %input-shop;
      border: 1px solid $color-mineshaft;
    }
    .msc-address-form__input{
      &::placeholder{
        color: $color-mineshaft;
      }
    }

    .checkout-date-of-birth input {
      @extend %date-picker-input-override;
      height: auto;
    }
  
    //checkbox
    
    .ms-checkout-billing-note {
      font-size: 11px;
      line-height: normal;
    }

    .ms-checkout-billing-address__input-checkbox,
    .msc-address-select__input-radio {
      @extend %form-check-input;
      margin-left: 0;
    }
    .form-check-input {
      margin-left: 0;
    }

    .ms-checkout-billing-address__shipping-address-label > span,
    .msc-address-select__item > .msc-address-detail,
    .checkout-delivery-options__label > span,
    .checkout-delivery-options__price,
    .checkout-terms-and-conditions > label > span {
      margin-left: 1.25rem;

      p {
        display: inline;
        margin: 0;
      }
    }

    .msc-address-form__alert:not(:empty),
    .ms-checkout-gift-card__input-error:not(:empty),
    .ms-checkout-guest-profile__input-error:not(:empty),
    .ms-checkout-date-of-birth__input-error:not(:empty),
    .checkout-date-of-birth__input-error:not(:empty) {
      @extend %error-alert;
      display: block;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
    }

    .msc-address-form__warning:not(:empty) {
      @extend %warning-alert;
      display: block;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
    }

    .ms-checkout__guided-card-footer {
      margin-top: 1.5rem;

      button {
        @extend %btn-default;
      }
      .btn-primary {
        @extend .btn-primary;
      }
      .btn-secondary {
        @extend .btn-secondary;
      }

      button:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  .ms-checkout__main-control {
    padding: 2rem 0 1rem;
  }

  .ms-checkout__btn-place-order {
    @extend %btn-default;
    @extend .btn-primary;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  .ms-checkout__btn-keep-shopping {
    @extend %link-shop-muted;
    margin-top: 1rem;
    font-family: $font-family-body;
    color: $color-mineshaft;
    font-size: $font-size-body-m;
    text-transform: capitalize;
    border: 0;
    padding: 0;
    letter-spacing: 0;
    display: inline-block;
    &:hover,
    &:focus,
    &:active {
      color: $color-link-muted;
      background-color: transparent;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem $color-mineshaft;
    }

    @include media-breakpoint-up(sm) {
      margin: 0 0 0 1rem;
    }
  }

  .msc-order-summary__heading {
    @extend %text-shop-heading-secondary;
    margin-bottom: 1rem;
  }

  .msc-order-summary__line-total {
    margin-bottom: 0;
    font-size: $font-size-body-l;
    text-transform: uppercase;
  }

  .ms-checkout__line-items {
    padding: 1rem 1.5rem;
    margin-top: 2rem;
    background-color: $color-whitelinen;

    .ms-checkout__line-items-header {
      float: right;
      line-height: 1.4;
      .ms-checkout__line-items-heading {
        display: none;
      }

      .ms-checkout__line-items-edit-cart-link {
        @extend %link-shop-muted;
        color: $color-mineshaft;
      }
    }

    .ms-checkout__line-items-delivery-group {
      .ms-checkout__line-items-group-title {
        @extend %text-shop-strong;
        margin-bottom: 0.5rem;
      }

      .msc-cart-line__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .msc-cart-line__product {
        width: 100%;
        font-size: 13px;
        &-price {
          font-size: 13px;
        }
      }

      .msc-cart-line__quantity {
        margin-left: 1rem;
        font-size: 13px;
        .quantity-label {
          margin-bottom: 0;
          &:after {
            content: ":";
          }
        }
        .quantity-value {
          margin-left: 0.155rem;
        }
      }
    }
  }

  .ms-checkout__title,
  .ms-checkout-billing-address__heading,
  .ms-checkout__guided-card-title-step,
  .msc-address-form__item-county,
  .ms-checkout__side-control-second,
  .msc-cart-line__product-image,
  .msc-cart-line-item-product-discount {
    display: none;
  }

  //order summary for checkout
  .msc-order-summary {
    &-wrapper {
      width: auto;
      margin: auto;
    }
    &__line {
      &-net-price {
        .msc-order-summary__label {
          color: $color-text-primary;
          font-size: $font-size-body-m;
          font-weight: normal;
        }
      }
      &-total {
        .msc-order-summary__label {
          color: $color-text-primary;
          font-weight: normal;
        }
      }
    }
  }
  .msc-address-form__alert {
    font-size: 13px;
    line-height: 1.5;
  }

  //prop65 warning
  .msc-p65-warning-icon > picture > img {
    max-width: 50px;
    text-align: right;
  }

  .msc-p65-warning-text {
    padding: 3px;
    font-size: smaller;
    border: 2px solid black;
  }

  .ms-checkout-guest-profile__input {
    input {
      @extend %form-control-partial;
      @extend %input-shop;
    }
  }
}
