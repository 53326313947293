$msv-sign-in-font-size: 14px;
$msv-sign-in-button-margin-top: 1rem;
$msv-sign-in-facebook-color: #3b5998;
$msv-sign-in-account-item-margin-top: 1rem;
$msv-cancel-button-margin-top: 0.5rem;

$width-page--aad: 100%;
$min-width-page--aad: 0;
$max-width-page--aad: 600px;
$margin-page--aad: 10rem auto 4rem;
$margin-page-mobile--aad: 2rem auto;
$padding-page--aad: 2rem auto 0;
$padding-page-mobile--aad: 1rem auto 0;
$color-background-container--aad: $color-white;
$error-alert-color: #721c24;
$error-alert-background-color: #f8d7da;
$error-alert-border-color: #f5c6cb;
$success-alert-color: #004085;
$success-alert-background-color: #cce5ff;
$success-alert-border-color: #b8daff;

%form-control-el {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ms-password-reset {
  max-width: $max-width-page--aad;
  font-size: $font-size-body-m;
  padding: 1.5rem 2rem;
  background-color: $color-background-container--aad;
  color: $color-text-primary;
  margin: $margin-page-mobile--aad;

  @include media-breakpoint-up(sm) {
    padding: 3rem 4rem;
  }
  @include media-breakpoint-up(lg) {
    margin: $margin-page--aad;
  }
  &__container {
    label {
      margin-bottom: 0;
    }
  }

  &__heading {
    @include account-heading();
    font-family: $font-family-heading-shop;
    font-weight: normal;
  }

  &__sign-in-section {
    flex: 1;
    h2,
    .h2 {
      margin-bottom: 2rem;
    }
  }
  &__sign-up-section {
    flex: 1;
    h2,
    .h2 {
      display: none;
    }
  }
  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;
    &-input {
      @extend %form-control-el;
      @extend %input-shop;
    }
  }
  &__forget-password {
    @extend %link-shop;
    width: auto;
  }
  &__sign-in-button {
    @extend %btn-default;
    @extend .btn-primary;
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
  }
  &__sign-up-link {
    @extend %btn-default;
    @extend .btn-secondary;
    width: 100%;
  }
  &__social-accounts {
    display: none;
  }

  &__email-verification-button,
  &__continue-button,
  &__cancel-button {
    width: 100%;
  }
  &__email-verification-button {
    margin-top: 1rem;
  }
  &__verify-email-send,
  &__verify-email-verify,
  &__continue-button {
    @extend %btn-default;
    @extend .btn-primary;
  }
  &__verify-email-resend,
  &__verify-email-edit,
  &__cancel-button {
    @extend %btn-default;
    @extend .btn-secondary;
  }
  &__verify-email-resend,
  &__continue-button {
    margin-top: $msv-sign-in-button-margin-top;
  }
  &__cancel-button {
    margin-top: $msv-cancel-button-margin-top;
  }
  &__continue-button {
    &.disabled,
    &:disabled {
      display: none;
    }
  }

  &__page-error,
  &__item-error {
    color: $error-alert-color;
    background-color: $error-alert-background-color;
    border: 1px solid $error-alert-border-color;
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    p {
      margin-bottom: 0;
      line-height: 1.5;
      display: inline-block;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
  }

  &__page-success {
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    color: $success-alert-color;
    background-color: $success-alert-background-color;
    border: 1px solid $success-alert-border-color;
    p {
      margin-bottom: 0;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
  }
  &__verifying-modal-message {
    text-align: center;
    color: $color-white;
    font-size: $font-size-body-xl;
  }
}

.password-reset-requirements {
  div {
    padding: revert;
  }

  .ms-password-reset {
    margin-top: 0;
  }
}
