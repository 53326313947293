body {
  width: 100%;
  background-image: $asset-background-marketing;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: $color-background-body;
  color: $color-text-primary;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  overflow-wrap: break-word;

  @include media-breakpoint-up(sm) {
      overflow-wrap: normal;
  }
}