.msc-address-detail {
  font-size: $font-size-body-m;
}

.msc-address-detail__item-newline,
.msc-address-detail__item-county,
.msc-address-detail__item-address-detail_Phone {
  display: block;
}

.msc-address-detail__item-city::after {
  content: ",";
}

.msc-address-detail__item-state {
  margin-left: 0.25rem;
}

.msc-address-detail__item-address-detail_Phone::before {
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-phone);
  display: inline-block;
  margin-right: 0.5rem;
  font-size: $font-size-icon-s;
}

.msc-address-detail__item-phone-label {
  display: none;
}

//Address Form
.msc-address-form {
  &__heading {
    @extend %text-shop-heading-secondary;
    margin-bottom: 1rem;
  }
  &__item {
    margin-top: 1rem;
    &-country {
      display: none;
    }
  }
  &__label {
    margin-bottom: 0;
  }

  &__input,
  &__dropdown {
    @extend %form-control-partial;
    @extend %input-shop;
  }
  &__button-save,
  &__button-cancel {
    margin-top: 1.5rem;
    @extend %btn-default;
  }
  &__button-save {
    @extend .btn-primary;
  }
  &__button-cancel {
    @extend .btn-secondary;
    margin-left: 1rem;
  }
  &__item-county {
    display: none;
  }
}
