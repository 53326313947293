footer {
  background-color: $color-background-footer;
  color: $color-text-secondary;
}

.footer {
  max-width: $max-marketing-page-width;
  color: $color-footer;
  font-family: $font-family-link;
  font-size: 14px;
  @include media-breakpoint-only(xs) {
    padding-bottom: 1rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 10px;
    padding: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 10px;
    padding: 1rem 0;
  }

  .row {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .col-12,
  ul {
    @extend %col-auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0;
    margin-top: 2rem;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
      text-align: center;
    }
  }

  .col-12,
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-top: 0;
      &:first-child {
        margin-top: 0;
      }
    }
    @include media-breakpoint-up(md) {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
  }
  //footer connect
  li.ms-footer__list-item {
    @include media-breakpoint-down(sm) {
      margin-top: 0.5rem;
    }
  }
  ul.footer-connect .nav-item {
    width: auto;
    content: "|";
    a {
      color: $color-footer-icons;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-secondary-hover;
        color: $color-link-social-icons;
      }
    }
  }

  //Footer Links
  li.footer-links:not(:first-child),
  li.footer-copyright:not(:first-child) {
    @include media-breakpoint-up(md) {
      > div::before {
        margin: 0 0.5rem;
        content: "|";
      }
    }
  }
  .ms-footer__link__text{
    color: $color-merino;
  }
  li.footer-copyright>.copyright{
    color: $color-merino;
  }
  .copyright::before{
    color: $color-footer;
  }
  ul.footer-links,
  ul.footer-copyright {
    color: $color-link-footer;
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
    @include media-breakpoint-up(md) {
      font-size: $font-size-link-s;
    }

    .nav-items:not(:first-child) {
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 1rem;
      }
    }

    a {
      @extend %link-nav-tertiary;
      font-size: $font-size-link-m;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
      @include media-breakpoint-up(md) {
        font-size: $font-size-link-s;
      }
    }

    .ms-text-block {
      > div {
        display: inline-block;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  ul.footer-enjoy {
    margin-bottom: 0.5rem;
    img {
      width: 100%;
      max-width: 140px;
      height: auto;
    }
  }
}

.footer .row {
  @include media-breakpoint-between(md, lg) {
    padding: 0 1rem;
  }
}

ul.footer-social-icons {
  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }
}
li.footer-social-icons {
  a {
    &:hover,
    &:focus,
    &:active {
      color: $color-link-social-icons;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0.5rem;
    padding-bottom: 2px;
  }
  &:not(:first-child) {
    margin-left: 1rem;
  }
}

.home-marketing div.ms-footer__item.social-media-item.social-media-item-youtube {
  padding-left: 0;
}
ul.mailing-list-footer {
  width: 100%;
  border-top: 1px solid $color-link-footer;
  .footer-mailing-list {
    margin: 0.5rem 0 1rem;
  }
  text-align: left;
  h5,
  .h5 {
    font-size: $font-size-body-l;
    color: $color-link-footer;
    text-transform: uppercase;
  }
  p {
    color: $color-link-footer-hover;
    margin-bottom: 0.75rem;
    font-size: $font-size-heading-xs;
  }
  a {
    @extend %btn-default;
    @extend .btn-primary;
    font-size: $font-size-link-s;
    &:hover,
    &:focus {
      color: $color-link-footer-hover;
    }
  }
}
