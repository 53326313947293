$msv-checkout-gift-card-max-flex-basis: 100%;
$msv-checkout-gift-card-margin: 20px;

$msv-checkout-gift-card-input-label-margin-bottom: 12px;

$msv-checkout-gift-card-button-height: 32px;
$msv-checkout-gift-card-button-border-radius: 2px;
$msv-checkout-gift-card-button-margin-left: 20px;

$msv-checkout-gift-card-input-height: 32px;
$msv-checkout-gift-card-input-border-radius: 2px;
$msv-checkout-gift-card-input-padding: 6px 8px;
$msv-checkout-gift-card-input-width: 40%;

$msv-checkout-gift-card-error-max-width: 512px;
$msv-checkout-gift-card-error-padding: 5px;
$msv-checkout-gift-card-error-margin-right: 12px;
$msv-checkout-gift-card-error-margin-bottom: 10px;
$msv-checkout-gift-card-error-icon-margin-right: 8px;

$msv-checkout-gift-card-item-text-width: 60%;

$msv-checkout-gift-card-btn-remove-border: none;
$msv-checkout-gift-card-btn-remove-padding: 0;

$msv-checkout-gift-card-remove-button-margin-left: 10px;

.ms-checkout {
  &-gift-card__form {
    display: flex;
    flex-flow: row wrap;
    .ms-checkout-gift-card__input-fields {
      width: 100%;
    }
  }

  &-gift-card {
    margin-top: $msv-checkout-gift-card-margin;
    margin-bottom: $msv-checkout-gift-card-margin;
    &__title {
      font-weight: $font-weight-600;
    }

    &__btn-apply {
      @extend .btn-secondary;
    }
    &__input-error {
      width: 100%;
    }
    &__item {
      margin-top: 1rem;
    }
    &__item-text {
      padding-right: 0.5rem;
    }
    &__btn-remove {
      @extend .btn-link;
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      font-size: $font-size-link-m;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
