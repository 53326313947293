.gift-card-balance-container {
  .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 0 0 100%;
    }

    > *:first-child {
      margin: 0;

      @include media-breakpoint-up(md) {
        flex: 1 1 30%;
      }

      .ms-content-block__image img {
        width: 100%;
        height: auto;
      }
    }

    > *:last-child {
      padding: 2rem;

      @include media-breakpoint-up(md) {
        flex: 1 1 70%;
      }

      .gift-card-balance-label {
        margin-bottom: 0;
        font-size: 13px;
      }

      .gift-card-balance-input {
        margin-bottom: 0.5rem;

        &-text {
          @extend %input-shop;
          width: 100%;
        }
      }

      .gift-card-balance-error,
      .gift-card-balance-message {
        font-size: 14px;
      }

      .gift-card-balance-button {
        @extend %btn-default;
        @extend .btn-primary;
        margin-top: 0.5rem;
      }
    }
  }
  .container-with-header-header{
    .container-with-header-text > p{
      font-size: 13px;
    }
  }
  .ms-content-block__details {
    padding: 0.5rem !important;
  }
}
.shop.smallfont13{
  font-size: 13px;
}