.event-list {
  &-empty,
  &-event {
    display: flex;
    flex-direction: column;
    font-size: $font-size-body-m;
    line-height: 1.5;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  &-name {
    @extend %link-marketing;
    order: 1;
    color: $color-link-primary;
    font-weight: bold;
    font-size: $font-size-body-s;
  }

  &-date {
    order: 2;
    font-weight: bold;
  }

  &-day,
  &-year-L {
    margin-left: 0.15rem;
  }

  &-day::after {
    content: ",";
  }

  &-separator::before {
    content: "-";
    margin: 0 0.25rem;
  }

  &-time {
    order: 3;

    &:not(:empty)::before {
      content: "Time:";
    }
  }

  &-location {
    order: 4;

    &:not(:empty)::before {
      content: "Location:";
    }
  }

  &-time,
  &-location {
    &:not(:empty)::before {
      margin-right: 0.25rem;
      font-family: $font-family-link;
      font-size: $font-size-link-xxs;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  &-intro {
    order: 5;
  }

  &-sold-out {
    order: 6;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-image,
  &-month-S,
  &-month-L,
  &-year-S,
  &-category,
  &-price,
  &-button {
    display: none;
  }
}
