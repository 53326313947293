//==============================================================================
// FONT VARIABLES
//==============================================================================

// Font Families
$font-family-system: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
$font-family-body: "Georgia", "Times", "Times New Roman", serif;
$font-family-link: "Arial", "Helvetica", sans-serif;
$font-family-arial: "Arial", "Helvetica", sans-serif;
$font-family-heading: $font-family-body;
$font-family-heading-shop: "Playfair Display", "Georgia", "Times", "Times New Roman", serif;

$font-family-body-landing: "Lato", "Lucida Grand", "Helvetica", "Arial", sans-serif;
$font-family-link-landing: $font-family-body-landing;
$font-family-button-landing: $font-family-body-landing;
$font-family-heading-landing: "Lora", "Times", "Times New Roman", "Georgia", serif;
$font-family-orderdetail: "chaparral-pro", "Times", "Times New Roman", "Georgia", serif;

// Font Sizes
$font-size-body-xxl: 20px;
$font-size-body-xl: 16px;
$font-size-body-l: 18px;
$font-size-body-m: 13px;
$font-size-body-m1: 14px;
$font-size-body-s: 14px;
$font-size-body-xs: 9.5px;
$font-size-body-xxs: 11px;

$font-size-link-xl: 18px;
$font-size-link-l: 16px;
$font-size-link-m: 14px;
$font-size-link-s: 12px;
$font-size-link-xs: 12px;
$font-size-link-xxs: 10px;

$font-size-heading-xl: 48px;
$font-size-heading-l: 32px;
$font-size-heading-m: 24px;
$font-size-heading-s: 15px;
$font-size-heading-xs: 12px;

$font-size-heading-shop-xl: 34px;
$font-size-heading-shop-l: 32px;
$font-size-heading-shop-m: 24px;
$font-size-heading-shop-s: 16px;
$font-size-heading-shop-xs: 14px;

$font-size-icon-l: 20px;
$font-size-icon-m: 16px;
$font-size-icon-s: 12px;

$font-size-heading-landing: 42px;
$font-size-appellation-landing: 18px;
$font-size-body-landing: 14px;
$font-size-link-landing: 13px;
$font-size-button-landing: 10px;

// Font Weights
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-600: 600;
$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;
$msv-font-weight-500: 500;
$msv-font-weight-400: 400;
$msv-font-weight-600: 600;

//==============================================================================
// BASE COLOR VARIABLES
//==============================================================================

// Standard
$color-white: #fff;
$color-black: #000;
$color-gallery: #efefef;
$color-silver: #ccc;
$color-dustygray: #999;
$color-dovegray: #666;
$color-codgray: #0a0a0a;
$color-red: #ff0000;
$color-verdungreen: #4a7801;
$color-danger: #dc3545;
// Theme
$color-whitelinen: #f5f4f0;
$color-merino: #f4eee1;
$color-tana: #dbd4c0;
$color-bisonhide: #bfb6a0;
$color-cloudy: #aba49b;
$color-kumera: #8f6628;
$color-pottersclay: #7e6033;
$color-merlin: #3c352f;
$color-zeus: #221e1a;
$color-mineshaft: #323232;
$color-header-container: #dbd4c0;
$color-footer: #7e6033;
$color-footer-icons: #8f6628;
$color-link-social-icons: #bfb6a0;
$color-dark-grey: #706a66;
//==============================================================================
// COMPONENT COLOR VARIABLES
//==============================================================================

// General
$color-disabled: $color-silver;
$color-error: $color-red;

// Background
$color-background-primary: $color-white;
$color-background-secondary: $color-header-container;
$color-background-tertiary: $color-merlin;

$color-background-body: $color-merino;
$color-background-body-accent: $color-tana;
$color-background-shop: $color-zeus;
$color-background-header: $color-tana;
$color-background-footer: $color-zeus;
$color-background-aad: $color-white;

// Border
$color-border-primary: $color-cloudy;
$color-border-secondary: $color-tana;
$color-border-tertiary: $color-dovegray;
$color-focus: $color-dark-grey;

// Text
$color-text-primary: $color-mineshaft;
$color-text-secondary: $color-merino;
$color-text-tertiary: $color-silver;
$color-text-muted: $color-dovegray;
$color-text-description: $color-dovegray;

// Heading
$color-heading-primary: $color-black;
$color-heading-secondary: $color-mineshaft;
$color-heading-tertiary: $color-white;

// Breadcrumb
$color-breadcrumb: $color-kumera;
$color-breadcrumb-hover: $color-merlin;
$color-breadcrumb-active: $color-mineshaft;

// Link
$color-link-primary: $color-kumera;
$color-link-primary-hover: $color-mineshaft;

$color-link-secondary: $color-mineshaft;
$color-link-secondary-hover: $color-kumera;

$color-link-tertiary: $color-bisonhide;
$color-link-tertiary-hover: $color-kumera;

$color-link-muted: $color-dustygray;
$color-link-muted-hover: $color-dovegray;

$color-link-header: $color-kumera;
$color-link-header-hover: $color-mineshaft;

$color-link-footer: $color-pottersclay;
$color-link-footer-hover: $color-bisonhide;

// Button
$color-button-primary-text: $color-white;
$color-button-primary-background: $color-kumera;
$color-button-primary-border: $color-kumera;
$color-button-primary-text-hover: $color-white;
$color-button-primary-background-hover: $color-merlin;
$color-button-primary-border-hover: $color-merlin;

$color-button-secondary-text: $color-kumera;
$color-button-secondary-background: transparent;
$color-button-secondary-border: $color-kumera;
$color-button-secondary-text-hover: $color-mineshaft;
$color-button-secondary-background-hover: transparent;
$color-button-secondary-border-hover: $color-mineshaft;

$color-button-disabled-text: $color-silver;
$color-button-disabled-background: $color-gallery;
$color-button-disabled-border: $color-silver;

$color-button-marketing-text: $color-white;
$color-button-marketing-background: $color-kumera;
$color-button-marketing-border: $color-kumera;
$color-button-marketing-text-hover: $color-white;
$color-button-marketing-background-hover: $color-merlin;
$color-button-marketing-border-hover: $color-merlin;

// Input
$color-input-text: $color-dovegray;
$color-input-background: $color-whitelinen;
$color-input-border: $color-silver;

// Shipping Map
$color-shipping-map-fill: $color-silver;
$color-shipping-map-fill-active: $color-verdungreen;
$color-shipping-map-stroke: $color-white;

// Landing
$color-background-landing-body: $color-background-body;
$color-background-landing-content: $color-whitelinen;
$color-heading-landing: $color-heading-secondary;
$color-appellation-landing: $color-text-description;
$color-text-landing: $color-heading-secondary;
$color-text-landing-footer: $color-heading-secondary;
$color-link-landing: $color-link-secondary;
$color-link-landing-hover: $color-link-secondary;
$color-button-landing-text: $color-button-primary-text;
$color-button-landing-background: $color-button-primary-background;
$color-button-landing-border: $color-button-primary-border;
$color-button-landing-text-hover: $color-button-primary-text-hover;
$color-button-landing-background-hover: $color-button-primary-background-hover;
$color-button-landing-border-hover: $color-button-primary-border-hover;
//==============================================================================
// Custom
//==============================================================================
$color-input-text-alternate: $color-dovegray;
$color-input-form-control: #495057;
$color-input-form-control-border: #ced4da;
$focus: #007bff auto 1px;

//Popover variables
$font-family-popover-default: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$popover-max-width: 276px;
$popover-font-size: 0.875rem;
$popover-border: 1px solid rgba(0, 0, 0, 0.2);
$popover-border-radius: 0.3rem;
$popover-body-color: #212529;

//Alert Variables
$error-alert-padding: 0.75rem 1.25rem;
$error-alert-margin-bottom: 1rem;
$error-alert-border-radius: 0.25rem;
$error-alert-color: #721c24;
$error-alert-background-color: #f8d7da;
$error-alert-border-color: #f5c6cb;
$success-alert-color: #004085;
$success-alert-background-color: #cce5ff;
$success-alert-border-color: #b8daff;
$warning-alert-color: #856404;
$warning-alert-background-color: #fff3cd;
$warning-alert-border-color: #ffeeba;

//==============================================================================
// ASSETS
//==============================================================================

$asset-background-marketing: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1IG2");
$asset-background-shop: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1IG5?q=80");
$asset-background-header: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1nRJ");
$asset-background-header-dropdown: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1Lle?q=80");
$asset-background-hero-promo: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1Llh");
$asset-list-icon: url("https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MA1IG8");
$asset-chevron-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E%0A");
$asset-calendar-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='141' height='146'%3E%3Cpath d='M13.3,126.4V37.4c0-2.4,.9-4.5,2.6-6.3c1.7-1.8,3.8-2.6%0A6.2-2.6h8.8v-6.7c0-3.1,1.1-5.7,3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0%0A5.6,1.1,7.8,3.3c2.2,2.2,3.2,4.8,3.2,7.9v6.7h26.4v-6.7c0-3.1,1.1-5.7%0A3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0,5.6,1.1,7.8,3.3c2.2,2.2,3.2%0A4.8,3.2,7.9v6.7h8.8c2.4,0,4.4,.9,6.2,2.6c1.7,1.8,2.6,3.8,2.6,6.3v88.9c0%0A2.4-.9,4.5-2.6,6.3c-1.7,1.8-3.8,2.6-6.2,2.6H22.1c-2.4,0-4.4-.9-6.2-2.6C14.2,130.8%0A13.3,128.8,13.3,126.4z M22.1,126.4h96.8V55.2H22.1V126.4z M39.7,41.9c0,.6,.2,1.2%0A.6,1.6c.4,.4,.9,.6,1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6,0-1.2,.2-1.6%0A.6c-.4,.4-.6,1-.6,1.6V41.9z%0AM92.5,41.9c0,.6,.2,1.2,.6,1.6c.4,.4,.9,.6%0A1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6%0A0-1.2,.2-1.6,.6c-.4,.4-.6,1-.6,1.6V41.9z'/%3E%3C/svg%3E");
