$order-summary-wrapper-margin-tab: 2rem 0 1rem;
$order-summary-wrapper-margin-desktop: 2rem 0 1rem auto;
$order-summary-wrapper-width-desktop: 50%;

//Cart order summary styles
.msc-order-summary {
  &-wrapper {
    line-height: 1.5;
    margin: $order-summary-wrapper-margin-tab;
    @include media-breakpoint-up(lg) {
      width: $order-summary-wrapper-width-desktop;
      margin: $order-summary-wrapper-margin-desktop;
    }
    .cart-order-summary-asterix {
      display: block;
      margin-bottom: 1rem;
      justify-content: unset;
      color: $color-dovegray;
      font-size: 11.5px;
      font-style: italic;
    }
  }
  &__items {
    p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.25rem;
      font-size: 13px;
    }
  }
  &__line {
    &-sub-total,
    &-total {
      .msc-order-summary__label {
        color: $color-mineshaft;
        font-size: $font-size-body-s;
        font-weight: bold;
      }
      .msc-order-summary__value {
        font-size: $font-size-body-m;
      }
    }
    &-total {
      margin-bottom: 0 !important;
    }
    &-tax-amount {
      @extend %order-summary-border-spacing;
    }
  }
}
//Cart checkout buttons styles
.checkout-btn-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
  }

  .msc-cart__btn-checkout {
    @extend %btn-default;
    @extend .btn-primary;
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .msc-cart__btn-guestcheckout {
    @extend %link-shop-muted;
    // display: none; //will be added after styling
    position: absolute;
    top: 5.5rem;
    left: 0;

    @include media-breakpoint-up(md) {
      top: 2.5rem;
    }

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: transparent;
      color: $color-link-muted-hover;
    }
  }

  .msc-cart__btn-backtoshopping {
    @extend %btn-default;
    @extend .btn-secondary;
  }
}
