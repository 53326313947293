.btn,
.shop .btn {
  border-radius: 0;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  .fa-chevron-right {
    display: none;
  }
}

.btn-link,
.shop .btn-link {
  color: $color-link-primary;

  &:hover,
  &:focus,
  &:active {
    color: $color-link-primary-hover;
  }
}

.btn-primary,
.shop .btn-primary {
  padding: 0.5rem 1rem;
  border-color: $color-button-primary-border;
  background-color: $color-button-primary-background;
  color: $color-button-primary-text;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  text-transform: uppercase;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-primary-border-hover;
    background-color: $color-button-primary-background-hover;
    color: $color-button-primary-text-hover;
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem $color-focus;
  }
}

.btn-secondary,
.shop .btn-secondary {
  @extend .btn-primary;
  border-color: $color-button-secondary-border;
  background-color: $color-button-secondary-background;
  color: $color-button-secondary-text;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-secondary-border-hover;
    background-color: $color-button-secondary-background-hover;
    color: $color-button-secondary-text-hover;
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem $color-focus;
  }
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled,
.shop .btn-primary.disabled,
.shop .btn-primary:disabled,
.shop .btn-secondary.disabled,
.shop .btn-secondary:disabled {
  border-color: $color-button-disabled-border;
  background-color: $color-button-disabled-background;
  color: $color-button-disabled-text;
  pointer-events: none;
  touch-action: none;
  opacity: 0.65;
}
