//==============================================================================
// IMPORTS
//==============================================================================
$fa-font-path: "https://use.fontawesome.com/releases/v6.6.0/webfonts" !default;
@import "https://fonts.googleapis.com/css?family=Playfair+Display";
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,400italic|Lora:400,700";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "bootstrap/scss/bootstrap";