.shop {
  .ms-order-history {
    &__sales-order-list,
    &__order-count {
      padding: 0 2rem;
      @include media-breakpoint-up(lg) {
        padding: 0 0 0 2rem;
      }
    }
    &__heading {
      padding: 0.8rem 1rem 0.5rem;
      margin-bottom: 2rem;
      background-color: $color-whitelinen;
      @extend %text-shop-heading-secondary;
      font-size: $font-size-body-s;
    }
    &__empty-message,
    &__order-details,
    &__alert {
      padding: 0 2rem;
    }
    &__alert {
      display: inline-block;
      margin-bottom: 1rem;
    }
    &__btn-keep-shopping {
      @extend %btn-default;
      @extend .btn-primary;
      margin-left: 2rem;
    }

    &__order-count {
      font-size: $font-size-body-s;
    }
    &__order-information {
      margin: 0.5rem 0;
      * {
        padding-right: 0.25rem;
        margin: 0;
      }
      *:not(:first-child) {
        padding: 0 0.25rem;
        border-left: 1px solid $color-border-primary;
        display: inline-block;
      }
      .ms-order-history__order-information-channel-reference-id {
        display: block;
        padding: 0;
        border: 0;
      }
    }
    &__sales-lines {
      margin: 1rem 0 0.5rem;
    }
    &__group-delivery-heading {
      margin-right: 0.25rem;
      font-weight: bold;
    }
    &__group-delivery-processing-label {
      display: flex;
    }
    &__sales-line-picture {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      img {
        border-radius: 0.15rem;
        height: 100%;
        max-height: 120px;
        max-width: 100px;
        width: auto;
      }
    }
    &__btn-order-details {
      @extend %btn-default;
      @extend .btn-secondary;
    }
    &__address-header {
      font-weight: bold;
      text-transform: capitalize;
    }
    &__address-full-address {
      display: flex;
      flex-direction: column;
    }
  }
}
